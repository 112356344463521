import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatNumber',
  standalone: true,
})
export class FormatNumberPipe implements PipeTransform {
  /*
   * Custom number formatter that returns a formatted string with the following rules:
   * - inserts a thin space (U+2009) as a thousands separator if the integer part exceeds 4 digits
   * - uses a period (.) as the decimal separator, regardless of locale
   * - allows configuration of min and max fraction digits; if not provided, retains the original decimal precision (between 0-15) of the input value
   * - returns an empty string if the input value is null or undefined
   *
   * {{ 19000.119191919 | formatNumber }}
   * result: "19 000.119191919"
   *
   * {{ 19000.119191919 | formatNumber: 0 : 2 }}
   * result: "19 000.12"
   *
   * {{ 2196800 | formatNumber: 0 : 2 }}
   * {{ 2196800 | formatNumber }}
   * result: "2 196 800"
   */
  transform(
    value: number | null | undefined,
    minimumFractionDigits: number | undefined = 0,
    maximumFractionDigits: number | undefined = 15
  ): string {
    if (value === null || value === undefined || isNaN(value)) return '';

    const [integer, decimal] = new Intl.NumberFormat('en-US', {
      minimumFractionDigits,
      maximumFractionDigits,
      useGrouping: false,
    })
      .format(value)
      .split('.');

    const formattedInteger =
      integer.length > 4
        ? integer.replace(/\B(?=(\d{3})+(?!\d))/g, '\u2009')
        : integer;

    return decimal ? `${formattedInteger}.${decimal}` : formattedInteger;
  }
}
