import * as am5 from '@amcharts/amcharts5';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5xy from '@amcharts/amcharts5/xy';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  InputSignal,
  NgZone,
  OnDestroy,
  Output,
  PLATFORM_ID,
  Signal,
  computed,
  effect,
  input,
} from '@angular/core';
import { RouterLink } from '@angular/router';
import { Subject } from 'rxjs';
import { OverlayDirective } from '../overlay/overlay.directive';
import { ToggleComponent } from '../toggle/toggle.component';
import {
  AmChartLine,
  AmLineChartItem,
  TimelineChartInput,
  TimelineChartSection,
} from './models/timeline-chart-input.interface';

@Component({
  selector: 'pozi-timeline-chart',
  standalone: true,
  imports: [CommonModule, OverlayDirective, ToggleComponent, RouterLink],
  templateUrl: './timeline-chart.component.html',
  styleUrl: './timeline-chart.component.scss',
})
export class TimelineChartComponent implements OnDestroy, AfterViewInit {
  data: InputSignal<TimelineChartInput> = input.required<TimelineChartInput>();

  timelineChartInput: Signal<TimelineChartInput> = computed(() => {
    const issecondaryTimelineConvertable =
      this.isTimelineConvertableFromIntervalToRatio(
        this.data().secondaryTimeline
      );
    const secondaryTimelineInMs = issecondaryTimelineConvertable
      ? this.getTimelineIntervalInMs(this.data().secondaryTimeline!)
      : 0;

    const ismainTimelineConvertable =
      this.isTimelineConvertableFromIntervalToRatio(
        this.data().mainTimeline.timelineSections
      );
    const mainTimelineInMs = ismainTimelineConvertable
      ? this.getTimelineIntervalInMs(this.data().mainTimeline.timelineSections)
      : 0;

    return {
      ...this.data(),
      secondaryTimeline: issecondaryTimelineConvertable
        ? this.data().secondaryTimeline?.map(
            (timelineChartSection: TimelineChartSection) =>
              this.convertTimelineIntervalToRatio(
                timelineChartSection,
                secondaryTimelineInMs
              )
          )
        : this.data().secondaryTimeline,
      mainTimeline: ismainTimelineConvertable
        ? {
            ...this.data().mainTimeline,
            gap: this.data().mainTimeline.gap ?? 0,
            timelineSections: this.data().mainTimeline.timelineSections.map(
              (timelineChartSection: TimelineChartSection) =>
                this.convertTimelineIntervalToRatio(
                  timelineChartSection,
                  mainTimelineInMs
                )
            ),
          }
        : this.data().mainTimeline,
    };
  });

  hasExactTimes: Signal<boolean> = computed(() => {
    return this.isTimelineConvertableFromIntervalToRatio(
      this.data().mainTimeline.timelineSections
    );
  });

  amChartToggles: Map<string, boolean> = new Map<string, boolean>();
  amChartSeries: Map<string, am5xy.XYSeries> = new Map<
    string,
    am5xy.XYSeries
  >();

  @Output() amChartMouseOver: EventEmitter<AmLineChartItem> =
    new EventEmitter<AmLineChartItem>();

  cdkOverlayPositionPairs: ConnectedPosition[] = [
    {
      offsetX: 0,
      offsetY: 8,
      originX: 'center',
      originY: 'bottom',
      overlayX: 'center',
      overlayY: 'top',
      panelClass: 'bottom',
    },
    {
      offsetX: 0,
      offsetY: -8,
      originX: 'center',
      originY: 'top',
      overlayX: 'center',
      overlayY: 'bottom',
      panelClass: 'top',
    },
    {
      offsetX: 8,
      offsetY: 0,
      originX: 'end',
      originY: 'center',
      overlayX: 'start',
      overlayY: 'center',
      panelClass: 'right',
    },
    {
      offsetX: -8,
      offsetY: 0,
      originX: 'start',
      originY: 'center',
      overlayX: 'end',
      overlayY: 'center',
      panelClass: 'left',
    },
  ];

  private root!: am5.Root;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private zone: NgZone
  ) {
    effect(() => {
      if (this.data().amChart) {
        this.data().amChart?.lines.forEach((amChartLine: AmChartLine) => {
          this.amChartToggles.set(amChartLine.key, !amChartLine.disabled);
        });
      }
    });
  }

  ngAfterViewInit() {
    this.browserOnly(() => {
      this.createAmChart();
    });
  }

  /**
   * @param timeline
   * @returns true, when all the TimelineChartSection elements have a startTime and an endTime
   */
  isTimelineConvertableFromIntervalToRatio(
    timeline: TimelineChartSection[] | undefined
  ): boolean {
    if (timeline) {
      return !timeline.find(
        (timelineChartSection: TimelineChartSection) =>
          !timelineChartSection.startTime || !timelineChartSection.endTime
      );
    } else {
      return false;
    }
  }

  convertTimelineIntervalToRatio(
    timelineChartSection: TimelineChartSection,
    allIntervalSum: number
  ): TimelineChartSection {
    return {
      ...timelineChartSection,
      ratio:
        (this.getIntervalInMs(
          timelineChartSection.startTime!,
          timelineChartSection.endTime!
        ) /
          allIntervalSum) *
        100,
    };
  }

  getTimelineIntervalInMs(
    timelineChartSection: TimelineChartSection[]
  ): number {
    return this.getIntervalInMs(
      timelineChartSection.at(0)!.startTime!,
      timelineChartSection.at(timelineChartSection.length - 1)!.endTime!
    );
  }

  getIntervalInMs(start: string | Date, end: string | Date): number {
    const startDate = start instanceof Date ? start : new Date(start);
    const endDate = end instanceof Date ? end : new Date(end);
    return endDate.getTime() - startDate.getTime();
  }

  readonly timeLabels = computed<string[]>(() => {
    return this.getHoursStringBetweenTimes(
      this.data().startingTime,
      this.data().endingTime
    );
  });

  readonly exactTimeLabels = computed<(Date | undefined)[]>(() => {
    const timelineSections = this.data().mainTimeline.timelineSections;
    const firstIntervalStart = new Date(timelineSections.at(0)!.startTime!);
    const lastIntervalEnd = new Date(
      timelineSections.at(timelineSections.length - 1)!.endTime!
    );
    return [
      firstIntervalStart,
      ...this.getHourlyDates(firstIntervalStart!, lastIntervalEnd!),
      lastIntervalEnd,
    ];
  });

  getHourlyDates(start: Date, end: Date): Date[] {
    const dates: Date[] = [];
    const currentDate = new Date(start);

    // Ensure the minutes and seconds are set to 0
    currentDate.setMinutes(0, 0, 0);
    currentDate.setHours(currentDate.getHours() + 1);

    while (currentDate <= end) {
      dates.push(new Date(currentDate));
      currentDate.setHours(currentDate.getHours() + 1);
    }

    // Exclude the first date if it's within 15 minutes of the start date
    if (dates.length > 1 && this.isLessThan15Minutes(dates.at(0)!, start)) {
      dates.shift();
    }

    // Exclude the last date if it's within 15 minutes of the end date
    if (
      dates.length > 1 &&
      this.isLessThan15Minutes(end, dates[dates.length - 1])
    ) {
      dates.pop();
    }

    return dates;
  }

  isLessThan15Minutes(date1: Date, date2: Date): boolean {
    const diffInMilliseconds = Math.abs(date2.getTime() - date1.getTime());
    const diffInMinutes = diffInMilliseconds / (1000 * 60);
    return diffInMinutes < 15;
  }

  getHoursStringBetweenTimes(startDate: string, endDate: string) {
    const startHour = new Date(startDate).getHours();
    const endTime = new Date(endDate);
    const endHour =
      endTime.getMinutes() === 0 ? endTime.getHours() : endTime.getHours() + 1;

    const hourArray = [];
    for (let i = startHour; i <= endHour; i++) {
      hourArray.push(i + ':00');
    }
    return hourArray;
  }

  getRelativePosition(time: Date | string, offSetInPixels: number): string {
    const percentage =
      (this.getIntervalInMs(
        this.data().mainTimeline.timelineSections.at(0)!.startTime!,
        time
      ) /
        this.getTimelineIntervalInMs(
          this.data().mainTimeline.timelineSections
        )) *
      100;

    return `calc(${percentage}% - ${offSetInPixels}px)`;
  }

  getOffSetForTimeLabel(
    offSetInPixels: number,
    isFirst: boolean,
    isLast: boolean
  ): number {
    if (isFirst) {
      return 0;
    } else if (isLast) {
      return offSetInPixels * 2;
    } else {
      return offSetInPixels;
    }
  }

  toggle(key: string, newValue: boolean): void {
    this.amChartToggles.set(key, newValue);
    const series = this.amChartSeries.get(key)!;

    const unit = this.data().amChart?.lines.find(
      (line: AmChartLine) => line.key === key
    )?.unit;

    series.states.applyAnimate(newValue ? 'highlighted' : 'faded');
    series.set(
      'tooltip',
      !newValue
        ? undefined
        : am5.Tooltip.new(this.root, {
            pointerOrientation: 'horizontal',
            labelText: '{valueY}' + (unit ? ' ' + unit : ''),
            keepTargetHover: true,
          })
    );
    if (newValue) {
      series.get('tooltip')!.events.on('dataitemchanged', this.emitDataContext);
    }
  }

  addTemporaryHighlight(hoveredKey: string): void {
    this.amChartSeries.forEach((series: am5xy.XYSeries, key: string) => {
      series.states.applyAnimate(key === hoveredKey ? 'highlighted' : 'faded');
    });
  }

  removeTemporaryHighlight(): void {
    this.amChartSeries.forEach((series: am5xy.XYSeries, key: string) => {
      series.states.applyAnimate(
        this.amChartToggles.get(key) ? 'highlighted' : 'faded'
      );
    });
  }

  // Run the function only in the browser
  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  createAmChart(): void {
    const root = am5.Root.new('chartdiv');
    root.setThemes([am5themes_Animated.new(root)]);
    root['_logo']!.dispose(); // hide the logo

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panY: false,
        layout: root.verticalLayout,
        width: am5.percent(100),
        height: am5.percent(100),
      })
    );

    const data = this.data()
      .amChart!.data.map((item) => {
        return {
          ...item.values,
          position: item.position,
          date: new Date(item.date).getTime(),
        };
      })
      .sort((a, b) => a.date - b.date);

    const xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        groupData: false,
        baseInterval: {
          timeUnit: 'second',
          count: 1,
        },
        renderer: am5xy.AxisRendererX.new(root, {
          minGridDistance: 20,
          strokeOpacity: 0, // Hide the left and right borders
        }),
        width: am5.percent(100),
        min: data.at(0)!.date,
        max: data.at(data.length - 1)?.date,
        //max: data.at(data.length)?.date,

        //tooltip: am5.Tooltip.new(root, {}),
      })
    );

    // Hide vertical grid lines
    xAxis.get('renderer').grid.template.setAll({
      forceHidden: true,
    });

    // Hide labels on the date axis
    xAxis.get('renderer').labels.template.setAll({
      visible: false,
    });

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {
          minGridDistance: 20,
          strokeOpacity: 0, // Hide the left and right borders
        }),
        min: 0, // Set the base value for the y-axis
      })
    );

    // Hide labels on the value axis
    yAxis.get('renderer').labels.template.setAll({
      visible: false,
    });

    const cursor = chart.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        xAxis: xAxis,
        behavior: 'none',
      })
    );
    cursor.lineY.set('visible', false);

    const createSeries = (
      name: string,
      field: string,
      color: string,
      disabled: boolean,
      unit?: string
    ) => {
      const settingsWithoutTooltip: am5xy.ILineSeriesSettings = {
        name: name,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: field,
        valueXField: 'date',
        locationX: 0,
        stroke: am5.color(color),
        fill: am5.color(color),
        showTooltipOn: 'always',
        snapTooltip: true,
      };

      const settingsWithTooltip: am5xy.ILineSeriesSettings = {
        ...settingsWithoutTooltip,
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: 'horizontal',
          labelText: '{valueY}' + (unit ? ' ' + unit : ''),
          keepTargetHover: true,
        }),
      };

      const settings = disabled ? settingsWithoutTooltip : settingsWithTooltip;

      const series = chart.series.push(am5xy.LineSeries.new(root, settings));

      series.data.setAll(data);

      series.strokes.template.setAll({
        strokeWidth: 2,
        lineJoin: 'round', // Make the line ends rounded
      });

      const gradient = am5.LinearGradient.new(root, {
        stops: [color, '#FFFFFF'].map((color, index) => ({
          color: am5.color(color),
          offset: index,
        })),
      });

      series.fills.template.setAll({
        fillGradient: gradient,
        fillOpacity: 0.5,
        visible: true,
      });

      // Create highlighted state
      series.states.create('highlighted', {
        opacity: 1,
      });

      // Create faded state
      series.states.create('faded', {
        opacity: 0.2,
      });

      if (disabled) {
        series.states.applyAnimate('faded');
      }

      if (!disabled) {
        series
          .get('tooltip')!
          .events.on('dataitemchanged', this.emitDataContext);
      }

      this.amChartSeries.set(field, series);
    };

    this.data().amChart?.lines.forEach((line: AmChartLine) => {
      createSeries(
        line.title,
        line.key,
        line.color,
        !!line.disabled,
        line.unit
      );
    });

    chart.appear(1000, 100);
    this.root = root;
  }

  emitDataContext = (event: any) => {
    const dataItem = event.target.dataItem;
    if (dataItem) {
      this.amChartMouseOver.emit(dataItem?.dataContext as AmLineChartItem);
    }
  };

  getSectionWidthCssValue(ratio: number, gap: number | undefined): string {
    return `calc(${ratio}% - ${gap}px)`;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
