import {
  Component,
  computed,
  EventEmitter,
  Input,
  input,
  InputSignal,
  Output,
  Signal,
} from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'pozi-toggle',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './toggle.component.html',
  styleUrl: './toggle.component.scss',
})
export class ToggleComponent {
  static counter = 0;

  defaultColor = '#8CC814';

  @Input() value = false;
  @Output() valueChange = new EventEmitter<boolean>();

  id: InputSignal<string | null> = input<string | null>(null);
  title: InputSignal<string | null> = input<string | null>(null);
  color: InputSignal<string> = input<string>(this.defaultColor);

  inputId: Signal<string> = computed(() => {
    return this.id() ?? `toggle-${ToggleComponent.counter}`;
  });

  constructor() {
    ToggleComponent.counter++;
  }
}
