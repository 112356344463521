import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ImageShowcaseComponent } from 'src/app/shared/image-showcase/image-showcase.component';
import { TileGridComponent } from 'src/app/shared/tile-grid/tile-grid.component';
import { TableCellComponent } from 'src/app/table/components/table-cell/table-cell.component';
import {
  ActionsDialog,
  DialogButtonColor,
  DialogTableColumn,
  GeneralDialog,
  GridDialog,
  ImageDialog,
  SelectDialog,
  TableDialog,
  TextEditorDialog,
  VideoDialog,
} from '../../models/dialog.interface';

@Component({
  selector: 'pozi-general-dialog',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TableCellComponent,
    TileGridComponent,
    ImageShowcaseComponent,
  ],
  templateUrl: './general-dialog.component.html',
  styleUrl: './general-dialog.component.scss',
})
export class GeneralDialogComponent {
  defaultRequiredLabel = $localize`Required`;

  DialogButtonColor = DialogButtonColor;

  formGroup?: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: GeneralDialog,
    public dialogRef: MatDialogRef<GeneralDialogComponent>,
    formBuilder: FormBuilder
  ) {
    if (data.type === 'text-editor') {
      this.formGroup = formBuilder.group({
        text: [this.textEditorData.value, this.textEditorData.validators ?? []],
      });
    }
    if (data.type === 'select') {
      this.formGroup = formBuilder.group({
        selectedOptionIndex: [
          this.selectData.selectedByDefaultIndex,
          this.selectData.required ? [Validators.required] : [],
        ],
      });
    }
  }

  // TODO: Once upgraded to Angular 18, replace this casts with @let inside the template

  get actionsData(): ActionsDialog {
    if (this.data.type !== 'actions') {
      throw new Error('Missuse in Dialog Type!');
    }
    return this.data as ActionsDialog;
  }

  get selectData(): SelectDialog {
    if (this.data.type !== 'select') {
      throw new Error('Missuse in Dialog Type!');
    }
    return this.data as SelectDialog;
  }

  get selectedOptionIndex(): number {
    if (this.data.type !== 'select') {
      throw new Error('Missuse in Dialog Type!');
    }
    return this.formGroup!.get('selectedOptionIndex')!.value;
  }

  get textEditorData(): TextEditorDialog {
    if (this.data.type !== 'text-editor') {
      throw new Error('Missuse in Dialog Type!');
    }
    return this.data as TextEditorDialog;
  }

  get tableData(): TableDialog {
    if (this.data.type !== 'table') {
      throw new Error('Missuse in Dialog Type!');
    }
    return this.data as TableDialog;
  }

  get gridData(): GridDialog {
    if (this.data.type !== 'grid') {
      throw new Error('Missuse in Dialog Type!');
    }
    return this.data as GridDialog;
  }

  get imageData(): ImageDialog {
    if (this.data.type !== 'image') {
      throw new Error('Missuse in Dialog Type!');
    }
    return this.data as ImageDialog;
  }

  get videoData(): VideoDialog {
    if (this.data.type !== 'video') {
      throw new Error('Missuse in Dialog Type!');
    }
    return this.data as VideoDialog;
  }

  convertMapToArray<T>(map: { [key: string | number]: T }): T[] {
    return Object.values(map);
  }

  closeDialog(callback?: () => void) {
    if (callback) {
      callback();
    }
    this.dialogRef.close();
  }

  selectOption(index: number): void {
    const selectedOptionIndexFormControl = this.formGroup!.get(
      'selectedOptionIndex'
    )!;
    if (!selectedOptionIndexFormControl.dirty) {
      selectedOptionIndexFormControl.markAsDirty();
    }
    if (this.selectedOptionIndex === index) {
      selectedOptionIndexFormControl.patchValue(null);
    } else {
      selectedOptionIndexFormControl.patchValue(index);
    }
  }

  getGridTemplateColumnsValue(): string {
    let value = '';
    this.convertMapToArray<DialogTableColumn>(
      this.tableData.table.columns
    ).forEach((column: DialogTableColumn) => {
      value += column.width ? column.width + 'px ' : ' 1fr';
    });
    return value;
  }

  getErrorTitle(errors: ValidationErrors | null | undefined): string {
    if (!errors) {
      return '';
    }

    // Extend the errorLabels with the defaultRequiredLabels
    const errorLabels: {
      [key: string]: string;
    } = {
      required: this.defaultRequiredLabel,
      ...this.textEditorData.errorLabels,
    };

    // Filter out the errorLabels, so only the present ones are in the object
    const filteredErrorLabels = Object.keys(errorLabels)
      .filter((key) => errors[key])
      .map((key: string) => errorLabels[key]);
    return filteredErrorLabels.join('\n');
  }
}
