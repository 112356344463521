import { TemplateRef } from '@angular/core';
import { MatSymbol } from './mat-symbol.interface';

export interface Marker {
  position: google.maps.LatLngLiteral;
  openOnInit?: boolean;
  matsymbol?: MatSymbol;
  infoWindowTemplate?: TemplateRef<any>;
  infoWindowData?: any;
  label?: string;
}

export interface Polyline {
  positions: google.maps.LatLngLiteral[];
  strokeColor?: string;
  strokeWeight?: number;
}

export interface Polygon {
  paths: google.maps.LatLngLiteral[];
  strokeWeight?: number;
  fillOpacity?: number;
}

export interface Svg {
  svg: string;
  bounds: Bounds;
  layer?: string;
}

export interface Bounds {
  min: google.maps.LatLngLiteral;
  max: google.maps.LatLngLiteral;
}

// map option settings for all google maps
export const baseMapOptions: google.maps.MapOptions = {
  mapId: '76ea02d65be105ff', // generated via Google Cloud Platform https://console.cloud.google.com/, a custom map style is assigned to the id
  clickableIcons: false,
  disableDefaultUI: true,
  tilt: 0,
};

export const createSvgOverlayClass = () => {
  return class SvgOverlay extends google.maps.OverlayView {
    private div: HTMLElement | undefined | null;

    constructor(
      private bounds: google.maps.LatLngBounds,
      private svg: string
    ) {
      super();
    }

    override onAdd(): void {
      this.div = document.createElement('div');
      this.div.style.borderStyle = 'none';
      this.div.style.borderWidth = '0px';
      this.div.style.position = 'absolute';
      this.div.className = 'svg-container';

      const svgElement = document.createElement('div');
      svgElement.innerHTML = this.svg;
      this.div.appendChild(svgElement);

      const panes = this.getPanes();
      panes!.overlayLayer.appendChild(this.div);
    }

    override draw(): void {
      const overlayProjection = this.getProjection();
      const sw = overlayProjection.fromLatLngToDivPixel(
        this.bounds.getSouthWest()
      );
      const ne = overlayProjection.fromLatLngToDivPixel(
        this.bounds.getNorthEast()
      );

      if (this.div) {
        this.div.style.left = sw!.x + 'px';
        this.div.style.top = ne!.y + 'px';
        this.div.style.width = ne!.x - sw!.x + 'px';
        this.div.style.height = sw!.y - ne!.y + 'px';
      }
    }

    override onRemove(): void {
      if (this.div) {
        this.div.parentNode!.removeChild(this.div);
        this.div = null;
      }
    }
  };
};

export const createMapOverlayClass = () => {
  return class MapOverlay extends google.maps.OverlayView {
    private div?: HTMLDivElement;
    private backgroundColor = '#14181C';
    private roadmapOpacity = '0.08';
    private satelliteOpacity = '0.24';

    constructor(private map: google.maps.Map) {
      super();
      this.setMap(map);
    }

    override onAdd() {
      this.div = document.createElement('div');
      this.div.classList.add('transparent-map-overlay');

      Object.assign(this.div.style, {
        position: 'absolute',
        background: this.backgroundColor,
        opacity:
          this.map.getMapTypeId() === 'roadmap'
            ? this.roadmapOpacity
            : this.satelliteOpacity,
        width: '100%',
        height: '100%',
        pointerEvents: 'none',
      });

      const panes = this.getPanes();
      panes!.mapPane.appendChild(this.div);
    }

    override draw() {
      if (!this.div) return;

      const projection = this.getProjection();
      const bounds =
        projection.getVisibleRegion()?.latLngBounds || this.map.getBounds();

      if (!bounds) return;

      const sw = projection.fromLatLngToDivPixel(bounds.getSouthWest())!;
      const ne = projection.fromLatLngToDivPixel(bounds.getNorthEast())!;

      const zoom = this.map.getZoom();
      const clientWidth = document.documentElement.clientWidth;
      const clientHeight = document.documentElement.clientHeight;

      // adding extra space to avoid frame upon drag or zoom changes
      const bufferX =
        zoom !== undefined && zoom <= 4 ? clientWidth : clientWidth / 2;
      const bufferY = clientHeight / 2;

      setTimeout(() => {
        Object.assign(this.div!.style, {
          left: `${sw.x - bufferX}px`,
          top: `${ne.y - bufferY}px`,
          width: `${ne.x - sw.x + 2 * bufferX}px`,
          height: `${sw.y - ne.y + 2 * bufferY}px`,
        });
      }, 0);
    }

    override onRemove() {
      this.div?.remove();
    }
  };
};
