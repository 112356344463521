<button
  class="dropdown-button"
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin"
  (click)="toggleDropdown()"
  [disabled]="disabled()"
>
  <i class="material-symbols-rounded">{{ icon() }}</i>
  @if (selectedItem && showTitle()) {
    <ng-container
      [ngTemplateOutlet]="template() ? template() : defTemplate"
      [ngTemplateOutletContext]="{ $implicit: selectedItem }"
    >
    </ng-container>
  }
</button>

<ng-template
  cdkConnectedOverlay
  #overlay="cdkConnectedOverlay"
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="dropdownOpen()"
  (overlayOutsideClick)="dropdownOpen.set(false)"
  [cdkConnectedOverlayPositions]="cdkOverlayPositionPairs"
  [cdkConnectedOverlayPanelClass]="'cdk-dropdown'"
>
  <div class="dropdown-content">
    @if (itemGroup()) {
      <div class="dropdown-item-group">{{ itemGroup() }}</div>
    }
    <ul class="item-list" role="menu">
      @for (item of items(); track item) {
        <li
          role="menuitem"
          tabindex="0"
          (click)="selectItem(item)"
          (keydown.enter)="selectItem(item)"
        >
          @if (item === selectedItem) {
            <i class="material-symbols-rounded">done</i>
          } @else {
            <span class="check-placeholder"></span>
          }
          <ng-container
            *ngTemplateOutlet="
              template() ? template() : defTemplate;
              context: { $implicit: item }
            "
          >
          </ng-container>
        </li>
      }
    </ul>
  </div>
</ng-template>

<ng-template #defTemplate let-item>
  <span>{{ item }}</span>
</ng-template>
