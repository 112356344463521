@if (images().length > 0) {
  <div class="image-showcase">
    <div class="main-display">
      <div class="image-container">
        <i
          class="material-symbols-rounded left-arrow"
          [class.disabled-arrow]="currentIndex === 0"
          (click)="showPreviousImage()"
          (keyup.enter)="showPreviousImage()"
          tabindex="0"
          >arrow_back</i
        >

        <img [src]="images()[currentIndex]" alt="{{ currentIndex }}" />

        <i
          class="material-symbols-rounded right-arrow"
          [class.disabled-arrow]="currentIndex === images().length - 1"
          (click)="showNextImage()"
          (keyup.enter)="showNextImage()"
          tabindex="1"
          >arrow_forward</i
        >
        <div class="counter">
          {{ currentIndex + 1 }} / {{ images().length }}
        </div>
        <div class="linear-gradient"></div>
      </div>
    </div>
    <div class="thumbnails" #thumbnails>
      @for (image of images(); track $index) {
        <img
          [src]="image"
          alt="{{ $index }}"
          [class.active]="$index === currentIndex"
          (click)="showImage($index)"
          (keyup.enter)="showImage($index)"
          [attr.tabindex]="2 + $index"
        />
      }
      <div class="scroll-gradient left-gradient" #leftScrollGradient></div>
      <div class="scroll-gradient right-gradient" #rightScrollGradient></div>
    </div>
  </div>
} @else {
  <div class="empty">
    <div class="empty-info">
      <i class="material-symbols-rounded">hide_image</i>
      <span>{{ emptyText() }}</span>
    </div>
  </div>
}
