import { Component, Inject, LOCALE_ID } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DropdownComponent } from '../../dropdown/dropdown.component';
import { LanguageService } from '../services/language.service';

@Component({
  selector: 'pozi-language-switch',
  standalone: true,
  imports: [DropdownComponent, FormsModule],
  templateUrl: './language-switch.component.html',
  styleUrl: './language-switch.component.scss',
})
export class LanguageSwitchComponent {
  languages = ['en', 'hu'];
  selectedLanguage = this.locale;

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private languageService: LanguageService
  ) {}

  onLanguageChange(language: string) {
    this.languageService.setAndSwitchLanguage(language as 'hu' | 'en');
  }
}
