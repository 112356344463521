import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import {
  SupportedLanguage,
  supportedLanguagesArray,
} from '../model/supported-languages.type';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private readonly LANGUAGE_COOKIE_NAME = 'pozi2_language';

  constructor(private cookieService: CookieService) {}

  getLanguageFromCookie(): SupportedLanguage {
    return this.cookieService.get(
      this.LANGUAGE_COOKIE_NAME
    ) as SupportedLanguage;
  }

  setAndSwitchLanguage(language: SupportedLanguage): void {
    const date = new Date();
    date.setFullYear(date.getFullYear() + 1);

    this.cookieService.set(this.LANGUAGE_COOKIE_NAME, language, {
      expires: date,
      path: '/',
    });
    this.switchLanguage(language);
  }

  switchLanguage(language: SupportedLanguage): void {
    let url = window.location.href;
    if (language === 'en') {
      url = url.replace(/\/hu\//g, '/en/');
    } else {
      url = url.replace(/\/en\//g, '/hu/');
    }
    window.location.replace(url);
  }

  switchToLanguageFromCookie(): void {
    const url = window.location.href;
    const languageCookie = this.getLanguageFromCookie();
    if (
      this.isLanguageSubPathPresentInUrl() &&
      !url.includes(`/${languageCookie}/`)
    ) {
      this.switchLanguage(languageCookie);
    }
  }

  isLanguageSubPathPresentInUrl(): boolean {
    const url = window.location.href;
    return supportedLanguagesArray.some((language: string) =>
      url.includes(`/${language}/`)
    );
  }
}
