import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError, EMPTY, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GeneralDialogComponent } from '../dialogs/components/general-dialog/general-dialog.component';
import { errorHandlers } from './error-handlers/error-handlers';
import { PoziErrorHandler } from './models/error-handler.interface';

export const httpErrorInterceptor: HttpInterceptorFn = (req, next) => {
  const dialog = inject(MatDialog);
  const snackBar = inject(MatSnackBar);

  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      if (!environment.production) {
        snackBar.open(`${error.status}\n${error.url}`, 'Close', {
          duration: 5000,
          panelClass: ['multiline-snackbar'],
        });
      }

      const errorHandler = errorHandlers.find((value: PoziErrorHandler) =>
        value.errorSelectorFn(error)
      );
      if (errorHandler) {
        if (typeof errorHandler.handleError === 'function') {
          errorHandler.handleError(error);
        } else {
          dialog.open(GeneralDialogComponent, {
            data: errorHandler.handleError,
          });
        }
        return EMPTY;
      } else {
        return throwError(() => error);
      }
    })
  );
};
