import { CommonModule } from '@angular/common';
import { Component, computed, input, InputSignal } from '@angular/core';
import { MapTile, Tile, TileGrid, TileType } from 'src/app/models/statistics';
import { ScrollFadeDirective } from 'src/app/table/directives/scroll-fade.directive';
import { CalendarChartComponent } from '../calendar-chart/calendar-chart.component';
import { CalendarChartInput } from '../calendar-chart/models/calendar-chart-input.interface';
import { GoogleMapsComponent } from '../google-maps/google-maps.component';
import { GoogleMapsInput } from '../google-maps/model/google-maps-input.interface';
import { TileComponent } from '../tile/tile.component';

@Component({
  selector: 'pozi-tile-grid',
  standalone: true,
  imports: [
    TileComponent,
    CommonModule,
    ScrollFadeDirective,
    CalendarChartComponent,
    GoogleMapsComponent,
  ],
  templateUrl: './tile-grid.component.html',
  styleUrl: './tile-grid.component.scss',
})
export class TileGridComponent {
  grid: InputSignal<TileGrid> = input.required<TileGrid>();

  readonly googleMapsInputMap = computed<Map<string, GoogleMapsInput>>(() => {
    const mapTile = new Map<string, GoogleMapsInput>();
    for (const [tileId, tile] of Object.entries(this.grid().tiles)) {
      if (this.isMapTile(tile)) {
        mapTile.set(
          tileId,
          this.getGoogleMapsInputFromMapTile(tile as MapTile)
        );
      }
    }
    return mapTile;
  });

  constructor() {}

  nonInteractiveGoogleMapsOptions: google.maps.MapOptions = {
    disableDefaultUI: true,
    scaleControl: false,
    draggable: false,
    zoomControl: false,
    scrollwheel: false,
    disableDoubleClickZoom: true,
    clickableIcons: false,
    keyboardShortcuts: false,
    maxZoom: 16,
  };

  readonly keys = computed<string[]>(() => {
    return Object.keys(this.grid().tiles);
  });

  readonly gridTemplateAreas = computed<string>(() => {
    return this.grid()
      .template.map((lineArray: string[]) => `'${lineArray.join(' ')}'`)
      .join('\n');
  });

  readonly gridTemplateColumns = computed<string>(() => {
    return this.grid()
      .template[0].map(() => '1fr')
      .join(' ');
  });

  readonly gridTemplateRows = computed<string>(() => {
    return this.grid()
      .template.map(() => '1fr')
      .join('');
  });

  getTile(key: string): Tile {
    return this.grid().tiles[key] as Tile;
  }

  getCalendarChart(key: string): CalendarChartInput {
    return this.grid().tiles[key] as CalendarChartInput;
  }

  getMapTile(key: string): MapTile {
    return this.grid().tiles[key] as MapTile;
  }

  getGoogleMapsInput(key: string): GoogleMapsInput {
    const mapTile: MapTile = this.getMapTile(key);
    return {
      drawings: mapTile.drawings,
      grayscale: true,
    };
  }

  isMapTile(tile: TileType): boolean {
    return Object.hasOwn(tile, 'drawings');
  }

  getGoogleMapsInputFromMapTile(mapTile: MapTile): GoogleMapsInput {
    return {
      ...mapTile,
      grayscale: true,
    };
  }
}
