import { ValidatorFn } from '@angular/forms';
import { MatSymbol } from 'src/app/models/mat-symbol.interface';
import { TileGrid } from 'src/app/models/statistics';
import { Row } from 'src/app/table/models/row';

export interface GeneralDialog {
  title: string;
  isCloseButtonDisplayed: boolean;
  text?: string;
  buttons: DialogButton[] | 'form-buttons';
  type?:
    | 'actions'
    | 'text-editor'
    | 'select'
    | 'table'
    | 'grid'
    | 'image'
    | 'video';
}

export interface DialogOption {
  matSymbol: MatSymbol;
  text: string;
  color?: string | DialogButtonColor;
}

export interface DialogButton extends DialogOption {
  callback?: () => void;
  isDisabled?: boolean;
}

export enum DialogButtonColor {
  GREEN = '#8CC814',
  GREY = '#E8EEF4',
  RED = '#F05014',
  YELLOW = '#F0C800',
}

export interface ActionsDialog extends GeneralDialog {
  actions: DialogButton[];
  type: 'actions';
}

export interface TextEditorDialog extends GeneralDialog {
  value?: string;
  rows?: number;
  validators?: ValidatorFn[];
  errorLabels?: {
    [key: string]: string;
  };
  placeholder?: string;
  type: 'text-editor';
  buttons: 'form-buttons';
}

export interface SelectDialog extends GeneralDialog {
  options: DialogOption[];
  selectedByDefaultIndex?: number;
  required?: boolean;
  type: 'select';
  buttons: 'form-buttons';
}

export interface TableDialog extends GeneralDialog {
  table: DialogTable;
  type: 'table';
}

export interface DialogTable {
  rows: { [rowId: number]: Row };
  columns: { [columnName: string]: DialogTableColumn };
}

export interface DialogTableColumn {
  title: string;
  width?: number;
}

export interface GridDialog extends GeneralDialog {
  grid: TileGrid;
  type: 'grid';
}

export interface ImageDialog extends GeneralDialog {
  images: string[];
  type: 'image';
}

export interface VideoDialog extends GeneralDialog {
  videoUrl: string;
  type: 'video';
}
