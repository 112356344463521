<div
  [ngClass]="
    data().amChart ? 'container-with-am-chart' : 'container-without-am-chart'
  "
>
  <span class="title">{{ data().title ?? 'Timeline' }}</span>
  <div class="item-container">
    @for (item of data().legendItems; track item) {
      <span>
        <i class="material-symbols-rounded" [style.color]="item.color">{{
          item.symbol
        }}</i>
        {{ item.name }}
      </span>
    }
    @if (data().amChart) {
      @for (line of data().amChart!.lines; track line) {
        <pozi-toggle
          class="toggle"
          [id]="line.key"
          [value]="amChartToggles.get(line.key)!"
          (valueChange)="toggle(line.key, $event)"
          [title]="line.title"
          [color]="line.color"
          (mouseenter)="addTemporaryHighlight(line.key)"
          (mouseleave)="removeTemporaryHighlight()"
        ></pozi-toggle>
      }
    }
  </div>
  @if (data().amChart) {
    <div id="chartdiv" class="am-chart"></div>
  }
  <div class="timeline">
    @if (data().secondaryTimeline) {
      <div class="parent-row">
        @for (
          section of timelineChartInput().secondaryTimeline;
          track section
        ) {
          <div
            class="thin-section"
            [style.background-color]="section.color"
            [style.width.%]="section.ratio"
            poziOverlay
            [template]="section.tooltip ? tooltipTemplate : null"
            [templateContext]="{ tooltip: section.tooltip }"
            [cdkOverlayPositionPairs]="cdkOverlayPositionPairs"
          ></div>
        }
      </div>
    }
    @if (data().mainTimeline.timelineSections) {
      <div
        class="parent-row colorized-timeline"
        [class.margin-for-pins]="data().mainTimeline.pins"
      >
        @for (
          section of timelineChartInput().mainTimeline.timelineSections;
          track section
        ) {
          <div
            class="section"
            [style.background-color]="section.color"
            [style.width]="
              getSectionWidthCssValue(
                section.ratio!,
                timelineChartInput().mainTimeline.gap
              )
            "
            [style.margin-left.px]="timelineChartInput().mainTimeline.gap! / 2"
            [style.margin-right.px]="timelineChartInput().mainTimeline.gap! / 2"
            poziOverlay
            [template]="section.tooltip ? tooltipTemplate : null"
            [templateContext]="{ tooltip: section.tooltip }"
            [cdkOverlayPositionPairs]="cdkOverlayPositionPairs"
          ></div>
        }
        @for (pin of data().mainTimeline.pins; track pin) {
          <div
            class="pin"
            [style.left]="getRelativePosition(pin.time, 16)"
            [routerLink]="pin.link"
            [style.cursor]="pin.link ? 'pointer' : 'inherit'"
          >
            <i class="material-symbols-rounded" [style.color]="pin.color">{{
              pin.symbol
            }}</i>
          </div>
        }
        @if (hasExactTimes()) {
          @for (time of exactTimeLabels(); track time) {
            <span
              class="relative-time-label"
              [style.left]="
                getRelativePosition(
                  time!,
                  getOffSetForTimeLabel(15, $first, $last)
                )
              "
              >{{ time | date: 'HH:mm' }}</span
            >
          }
        }
      </div>
    }
  </div>
  @if (!hasExactTimes()) {
    <div class="time">
      @for (time of timeLabels(); track time) {
        <span>{{ time }}</span>
      }
    </div>
  }
</div>

<ng-template #tooltipTemplate let-tooltip="tooltip">
  <div class="tooltip">
    {{ tooltip }}
  </div>
</ng-template>
