import { ConnectedPosition, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  Directive,
  ElementRef,
  HostListener,
  OnDestroy,
  TemplateRef,
  ViewContainerRef,
  computed,
  effect,
  input,
  signal,
} from '@angular/core';
import {
  defaultDropdownPositionPairs,
  defaultTooltipPositionPairs,
} from './cdk-overlay-position-pairs';

@Directive({
  selector: '[poziOverlay]',
  standalone: true,
  exportAs: 'overlay',
})
export class OverlayDirective implements OnDestroy {
  trigger = input<'click' | 'hover'>('hover');
  template = input<TemplateRef<any> | null | undefined>();
  templateContext = input<any>({});
  overlayClass = input<string>();
  cdkOverlayPositionPairs = input<ConnectedPosition[]>();

  private isOpen = signal(false);
  private overlayRef: OverlayRef | null = null;

  private panelClass = computed<string>(
    () =>
      this.overlayClass() || (this.isTooltip() ? 'cdk-tooltip' : 'cdk-dropdown')
  );

  private positionPairs = computed<ConnectedPosition[]>(
    () =>
      this.cdkOverlayPositionPairs() ||
      (this.isTooltip()
        ? defaultTooltipPositionPairs
        : defaultDropdownPositionPairs)
  );

  constructor(
    private overlay: Overlay,
    private elementRef: ElementRef,
    private viewContainerRef: ViewContainerRef
  ) {
    effect(
      () => {
        if (this.isOpen()) {
          this.show();
        } else {
          this.hide();
        }
      },
      { allowSignalWrites: true }
    );
  }

  @HostListener('mouseenter') onMouseEnter() {
    if (this.template() && this.isTooltip()) this.isOpen.set(true);
  }

  @HostListener('mouseleave') onMouseLeave() {
    if (this.template() && this.isTooltip()) this.isOpen.set(false);
  }

  @HostListener('click') onClick() {
    if (this.template() && !this.isTooltip()) this.isOpen.update((o) => !o);
  }

  private show() {
    if (this.overlayRef || !this.template()) return;

    this.overlayRef = this.createOverlayRef();
    this.addOutsideClickEventListener();
    this.addDetachmentsListener();
    this.setTriggerWidthCSSVar();

    const portal = new TemplatePortal(
      this.template()!,
      this.viewContainerRef,
      this.templateContext()
    );

    this.overlayRef?.attach(portal);
    this.addVisibleClassToTooltip();
  }

  private createOverlayRef(): OverlayRef {
    return this.overlay.create({
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.elementRef)
        .withPositions(this.positionPairs()),
      panelClass: this.panelClass(),
      hasBackdrop: false,
      scrollStrategy: this.overlay.scrollStrategies.close(),
    });
  }

  private addOutsideClickEventListener() {
    if (!this.isTooltip()) {
      this.overlayRef?.outsidePointerEvents().subscribe((e) => {
        if (!this.elementRef.nativeElement.contains(e.target)) {
          this.isOpen.set(false);
        }
      });
    }
  }

  private addDetachmentsListener() {
    this.overlayRef?.detachments().subscribe(() => {
      if (this.isOpen()) {
        this.isOpen.set(false);
      }
    });
  }

  private setTriggerWidthCSSVar() {
    if (this.isTooltip()) {
      this.overlayRef?.overlayElement?.style.setProperty(
        '--triggerElWidth',
        `${(this.elementRef.nativeElement as HTMLElement).clientWidth}`
      );
    }
  }

  private addVisibleClassToTooltip() {
    if (this.isTooltip()) {
      requestAnimationFrame(() => {
        this.overlayRef?.overlayElement?.classList.add('visible');
      });
    }
  }

  private isTooltip(): boolean {
    return this.trigger() === 'hover';
  }

  private hide() {
    this.overlayRef?.dispose();
    this.overlayRef = null;
  }

  destroy() {
    this.isOpen.set(false);
    this.hide();
  }

  ngOnDestroy() {
    this.destroy();
  }
}
