import { Pipe, PipeTransform } from '@angular/core';
import { format } from 'date-fns';

@Pipe({
  name: 'formatDate',
  standalone: true
})
export class FormatDatePipe implements PipeTransform {

  transform(date: string, dateFormat?: string) {
    return format(new Date(date), dateFormat || 'yy.MM.dd HH:mm');
  }

}
