<ng-template #tooltipTemplate let-tooltip="tooltip">
  <div class="tooltip" [class.tooltip-block]="tooltip?.length > 1">
    @for (t of tooltip; track $index) {
      @if (t.category) {
        <div class="tooltip-item">
          @if (t.matsymbol) {
            <i
              class="material-symbols-rounded"
              [ngStyle]="{
                'padding-right': '6px',
                'font-variation-settings': t.matsymbol.fill && '\'FILL\' 1',
                'font-size': t.matsymbol.size
                  ? t.matsymbol.size + 'px'
                  : '10px',
                color: t.color,
              }"
              >{{ t.matsymbol.symbol }}</i
            >
          }
          <span
            ><b>{{ t.category }}:</b> {{ t.value | formatNumber: 0 : 2 }}%</span
          >
        </div>
      }
    }
  </div>
</ng-template>

<div
  class="donut-chart-container"
  [id]="chart().id + '-donut-chart'"
  poziOverlay
  [template]="chart().data[0].category ? tooltipTemplate : undefined"
  [templateContext]="{ tooltip: chart().data }"
  [cdkOverlayPositionPairs]="cdkOverlayPositionPairs"
>
  <svg width="100%" height="100%" [attr.viewBox]="viewBox" class="donut">
    <circle
      class="donut-ring"
      [attr.cx]="center"
      [attr.cy]="center"
      [attr.r]="radius"
      fill="transparent"
      [attr.stroke]="ringStrokeColor"
      [attr.stroke-width]="strokeWidth"
    ></circle>

    @for (slice of slices; track slice) {
      <circle
        class="donut-slice"
        [attr.cx]="center"
        [attr.cy]="center"
        [attr.r]="radius"
        fill="transparent"
        [attr.stroke]="slice.color"
        [attr.stroke-width]="strokeWidth"
        [attr.stroke-dasharray]="slice.strokeDasharray"
        [attr.stroke-dashoffset]="slice.strokeDashoffset"
        stroke-linecap="round"
      ></circle>
    }
  </svg>
</div>
