import { ConnectedPosition } from '@angular/cdk/overlay';
import { Component, computed, input, InputSignal, Signal } from '@angular/core';
import { FormatDatePipe } from '../../table/pipes/format-date.pipe';
import { OverlayDirective } from '../overlay/overlay.directive';
import { calendarChartDefaultColorPalette } from './models/calendar-chart-default-color-palettte';
import {
  CalendarChartInput,
  CalendarChartItem,
} from './models/calendar-chart-input.interface';

@Component({
  selector: 'pozi-calendar-chart',
  standalone: true,
  imports: [OverlayDirective, FormatDatePipe],
  templateUrl: './calendar-chart.component.html',
  styleUrl: './calendar-chart.component.scss',
})
export class CalendarChartComponent {
  readonly defaultColors = calendarChartDefaultColorPalette;

  data: InputSignal<CalendarChartInput> = input.required<CalendarChartInput>();
  isInTileRow: InputSignal<boolean> = input<boolean>(false);
  maxBarHeightInPixels: Signal<number> = computed(() => {
    return this.isInTileRow() ? 40 : 64;
  });
  cdkOverlayPositionPairs: ConnectedPosition[] = [
    {
      offsetX: 8,
      offsetY: 0,
      originX: 'end',
      originY: 'center',
      overlayX: 'start',
      overlayY: 'center',
      panelClass: 'right',
    },
    {
      offsetX: -8,
      offsetY: 0,
      originX: 'start',
      originY: 'center',
      overlayX: 'end',
      overlayY: 'center',
      panelClass: 'left',
    },
    {
      offsetX: 0,
      offsetY: 8,
      originX: 'center',
      originY: 'bottom',
      overlayX: 'center',
      overlayY: 'top',
      panelClass: 'bottom',
    },
    {
      offsetX: 0,
      offsetY: -8,
      originX: 'center',
      originY: 'top',
      overlayX: 'center',
      overlayY: 'bottom',
      panelClass: 'top',
    },
  ];

  readonly dateLabels = computed<(number | string)[]>(() => {
    return this.data().calendarChartItems.map(
      (chartItem: CalendarChartItem) => {
        if (chartItem.label) {
          return chartItem.label;
        } else {
          return chartItem.date ? new Date(chartItem.date).getDate() : '';
        }
      }
    );
  });

  getBarHeightInPixels(calendarChartItem: CalendarChartItem): number {
    // When the chart is empty, we display a 8px x 8px filled circle
    if (this.isEmpty(calendarChartItem)) {
      return 8;
    }
    // When the 'heightPercentage' is specified, we calculate it from the max height
    if (calendarChartItem.heightPercentage) {
      return (
        (this.maxBarHeightInPixels() / 100) * calendarChartItem.heightPercentage
      );
    } else {
      // When the 'heightPercentage' is not specified, we take the max height
      return this.maxBarHeightInPixels();
    }
  }

  /**
   * Empty bar is without 'filledPercentage' and 'heightPercentage'
   */
  isEmpty(calendarChartItem: CalendarChartItem): boolean {
    if (calendarChartItem.filledPercentage === 0) {
      return false;
    }
    return (
      !calendarChartItem.filledPercentage && !calendarChartItem.heightPercentage
    );
  }
}
