<div #mapContainer class="map">
  <google-map
    (mapInitialized)="onMapInitialized($event)"
    height="100%"
    width="100%"
    [options]="mapOptions()"
    (mapClick)="infoWindow?.close()"
    [class.grayscale]="input().grayscale"
    (zoomChanged)="updateSvgPathStrokeWidth()"
    (mapDrag)="onMapDrag()"
  >
    @for (marker of markers(); track $index) {
      <div style="display: none">
        <div
          #markerContent
          [style.pointer-events]="marker!.infoWindowTemplate ? 'auto' : 'none'"
        >
          <button class="marker-content">
            <i
              [class]="
                'material-symbols-' +
                (marker.matsymbol?.style ? marker.matsymbol?.style : 'rounded')
              "
              [style.color]="marker.matsymbol?.color || '#14181C'"
              [style.transform]="
                marker.matsymbol?.rotationAngle &&
                'rotate(' + marker.matsymbol?.rotationAngle + 'deg)'
              "
              [style.font-variation-settings]="'\'FILL\' 1'"
            >
              {{ marker.matsymbol?.symbol || 'location_on' }}
            </i>
          </button>
          @if (marker.label) {
            <span class="pin-label">{{ marker.label }}</span>
          }
        </div>

        <div #infoWindowContent class="info-window-content">
          @if (marker.infoWindowTemplate) {
            <ng-container
              [ngTemplateOutlet]="marker.infoWindowTemplate!"
              [ngTemplateOutletContext]="{ $implicit: marker }"
            ></ng-container>
          }
        </div>
      </div>
      <map-info-window #infoWindow="mapInfoWindow"></map-info-window>

      <map-advanced-marker
        #advancedMarker="mapAdvancedMarker"
        [position]="marker.position"
        [content]="markerContent"
        (markerInitialized)="
          marker.openOnInit &&
            openInfoWindow(
              advancedMarker,
              infoWindowContent,
              infoWindow,
              $index
            )
        "
        (mapClick)="
          openInfoWindow(advancedMarker, infoWindowContent, infoWindow, $index)
        "
      />
    }

    @for (polygon of polygons(); track $index) {
      <map-polygon
        [paths]="polygon.paths"
        [options]="{
          clickable: false,
          draggable: false,
          strokeWeight: polygon.strokeWeight ?? 2,
          fillOpacity: polygon.fillOpacity ?? 0.1,
        }"
      />
    }

    @for (polyline of polylines(); track $index) {
      <map-polyline
        [options]="{
          clickable: false,
          draggable: false,
          strokeWeight: polyline.strokeWeight || 2,
          strokeColor: polyline.strokeColor || '#14181C',
        }"
        [path]="polyline.positions"
      />
    }
  </google-map>

  @for (svg of svgs(); track $index) {
    <div #svgContainer class="svg" [attr.data-index]="$index"></div>
  }

  <div class="controls-container" [class.visible]="!!map">
    @if (input().timeline && input().timeline?.amChart) {
      <div class="bottom-center">
        <pozi-timeline-chart
          [data]="input().timeline!"
          (amChartMouseOver)="setMarkerFromTimeline($event)"
        ></pozi-timeline-chart>
      </div>
    }

    @if (this.isBackToOverviewButtonEnabled()) {
      <div class="top-mid-controls">
        @if (freeRoam() && !isDrawingsEmpty()) {
          <button
            type="button"
            (click)="onBackToOverviewButtonClick()"
            [class.blurred]="map?.getMapTypeId() !== 'roadmap'"
          >
            <i class="material-symbols-rounded">zoom_out_map</i>
            <span i18n>Back to overview</span>
          </button>
        }
      </div>
    }

    @if (hasMapTypeControl()) {
      <div class="controls right-top-controls">
        <div class="maptype-control">
          @for (
            mapTypeId of mapTypeIds | keyValueOriginalOrder;
            track mapTypeId.key
          ) {
            <button
              type="button"
              [class.selected]="map?.getMapTypeId() === mapTypeId.key"
              [class]="mapTypeId.key"
              (click)="setMapTypeId(mapTypeId.key)"
            >
              <span>
                {{ mapTypeId.value }}
              </span>
            </button>
          }
        </div>
      </div>
    } @else if (routerLink()) {
      <a class="router-link action" [routerLink]="routerLink()">
        <i _ngcontent-ng-c1270111952="" class="material-symbols-rounded"
          >north_east</i
        >
      </a>
    }

    <div
      class="controls right-bottom-controls"
      [class.moved-up]="input().timeline && input().timeline?.amChart"
    >
      @if (layers().length > 0) {
        <div
          class="layers-control"
          [class.blurred]="map?.getMapTypeId() !== 'roadmap'"
        >
          <ng-template
            cdkConnectedOverlay
            #overlay="cdkConnectedOverlay"
            [cdkConnectedOverlayOrigin]="dropdownToggle"
            [cdkConnectedOverlayOpen]="isLayerSelectorDropdownOpen()"
            (overlayOutsideClick)="isLayerSelectorDropdownOpen.set(false)"
            [cdkConnectedOverlayPanelClass]="'cdk-dropdown'"
            [cdkConnectedOverlayPositions]="
              layerSelectorCdkOverlayPositionPairs
            "
          >
            <div class="dropdown-content">
              <ul class="item-list">
                @for (layer of layers(); track $index) {
                  <li (click)="onLayerClick(layer.key)">
                    @if (selectedLayers().includes(layer.key)) {
                      <i class="material-symbols-rounded">done</i>
                    } @else {
                      <span class="check-placeholder"></span>
                    }
                    {{ layer.label }}
                  </li>
                }
              </ul>
            </div>
          </ng-template>
          <button
            type="button"
            cdkOverlayOrigin
            #dropdownToggle="cdkOverlayOrigin"
            (click)="toggleLayerSelectorDropdown()"
          >
            <i class="material-symbols-rounded">layers</i>
          </button>
        </div>
      }

      @if (hasFullScreenControl()) {
        <div
          class="fullscreen-control"
          [class.blurred]="map?.getMapTypeId() !== 'roadmap'"
        >
          <button poziFullScreen [elementToSendFullScreen]="mapContainer">
            <i class="material-symbols-rounded">fullscreen</i>
          </button>
        </div>
      }

      @if (hasZoomControl()) {
        <div
          class="zoom-control"
          [class.blurred]="map?.getMapTypeId() !== 'roadmap'"
        >
          <button (click)="zoomOut()" class="zoom-control-out">
            <i class="material-symbols-rounded">remove</i>
          </button>
          <span class="line-between-controls"></span>
          <button (click)="zoomIn()" class="zoom-control-in">
            <i class="material-symbols-rounded">add</i>
          </button>
        </div>
      }
    </div>
  </div>
</div>
