import { DialogButton, DialogButtonColor } from './dialog.interface';

export const submitDialogButton: DialogButton = {
  text: $localize`:@@dialog.button.submit:Submit`,
  color: DialogButtonColor.GREEN,
  matSymbol: {
    symbol: 'check',
    color: '#FEFEFE',
  },
  callback: () => {},
};

export const cancelDialogButton: DialogButton = {
  text: $localize`:@@dialog.button.cancel:Cancel`,
  color: DialogButtonColor.GREY,
  matSymbol: {
    symbol: 'close',
    color: '#D9D9D9',
  },
  callback: () => {},
};

export const backDialogButton: DialogButton = {
  matSymbol: {
    symbol: 'arrow_back',
  },
  text: $localize`:@@dialog.button.back:Back`,
  color: DialogButtonColor.GREY,
  callback: () => {
    history.back();
  },
};
