import { HttpErrorResponse } from '@angular/common/http';
import { backDialogButton } from '../../dialogs/models/buttons.const';
import { PoziErrorHandler } from '../models/error-handler.interface';

export const errorHandlers: PoziErrorHandler[] = [
  {
    errorSelectorFn: (error: HttpErrorResponse) =>
      error.status === 404 && error.url!.endsWith('/filters'),
    handleError: {
      buttons: [],
      isCloseButtonDisplayed: true,
      title: $localize`:@@dialog.error.filters-not-found.title:Filters not found`,
      text: $localize`:@@dialog.error.filters-not-found.text:We can't find the saved filters for the displayed table.`,
    },
  },
  {
    errorSelectorFn: (error: HttpErrorResponse) => error.status === 404,
    handleError: {
      buttons: [backDialogButton],
      isCloseButtonDisplayed: false,
      title: $localize`:@@dialog.error.page-not-found.title:Page not found`,
      text: $localize`:@@dialog.error.page-not-found.text:We can't find the page you are looking for.`,
    },
  },
];
