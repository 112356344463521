<div class="content">
  <div class="header">
    <span class="title">{{ data.title }}</span>
    @if (data.isCloseButtonDisplayed) {
      <button type="button" (click)="dialogRef.close()">
        <i class="material-symbols-rounded">close</i>
      </button>
    }
  </div>
  @if (data.text) {
    <div class="description">{{ data.text }}</div>
  }
  @if (data.type === 'text-editor') {
    <div class="input" [formGroup]="formGroup!">
      @if (!textEditorData.rows || textEditorData.rows > 1) {
        <textarea
          formControlName="text"
          [placeholder]="textEditorData.placeholder"
          [rows]="textEditorData.rows ?? 8"
        >
        </textarea>
      } @else {
        <input
          type="text"
          formControlName="text"
          [placeholder]="textEditorData.placeholder"
        />
      }
      @if (formGroup!.get('text')!.invalid) {
        <i
          class="material-symbols-rounded error-icon"
          title="{{ getErrorTitle(formGroup!.get('text')?.errors) }}"
          i18n-title
          >error</i
        >
      }
    </div>
  }
</div>

@switch (data.type) {
  @case ('actions') {
    @if (actionsData.actions.length > 0) {
      <div class="action-container">
        @for (button of actionsData.actions; track $index) {
          <ng-container
            *ngTemplateOutlet="buttonTemplate; context: { $implicit: button }"
          ></ng-container>
        }
      </div>
    }
  }

  @case ('select') {
    @if (selectData.options.length > 0) {
      <div class="option-container">
        @for (button of selectData.options; track $index) {
          <button
            (click)="selectOption($index)"
            type="button"
            [style.background-color]="
              selectedOptionIndex === $index ? button.color : '#E8EEF4'
            "
            [style.color]="
              selectedOptionIndex === $index ? '#FEFEFE' : '#8CA0B4'
            "
          >
            <i
              class="material-symbols-rounded"
              [style.color]="
                selectedOptionIndex === $index ? '#FEFEFE' : '#2C343C'
              "
              >{{ button.matSymbol.symbol }}</i
            >
            <span>{{ button.text }}</span>
          </button>
        }
      </div>
    }
  }

  @case ('table') {
    <div class="row-container">
      @for (row of convertMapToArray(tableData.table.rows); track $index) {
        <div
          class="row"
          [style.grid-template-columns]="getGridTemplateColumnsValue()"
        >
          @for (cell of convertMapToArray(row.cells); track $index) {
            <pozi-table-cell
              class="dialog-table-cell"
              [row]="{ cells: {} }"
              [rowId]="0"
              [cell]="cell"
            ></pozi-table-cell>
          }
        </div>
      }
    </div>
  }

  @case ('grid') {
    <pozi-tile-grid [grid]="gridData.grid"></pozi-tile-grid>
  }

  @case ('image') {
    <pozi-image-showcase [images]="imageData.images"></pozi-image-showcase>
  }

  @case ('video') {
    <video width="100%" controls>
      <source src="{{ videoData.videoUrl }}" type="video/mp4" />
    </video>
  }

  @default {}
}

@if (data.buttons && data.buttons.length > 0) {
  <div class="button-container">
    @if (data.buttons === 'form-buttons') {
      <button
        type="button"
        [style.background-color]="DialogButtonColor.GREEN"
        [style.color]="'#FEFEFE'"
        [disabled]="formGroup!.invalid || !formGroup!.dirty"
        (click)="dialogRef.close(formGroup!.value)"
      >
        <i class="material-symbols-rounded">check</i>
        <span i18n>Submit</span>
      </button>

      <button
        type="button"
        [style.background-color]="DialogButtonColor.GREY"
        [style.color]="'#14181C'"
        (click)="dialogRef.close()"
      >
        <i class="material-symbols-rounded">close</i>
        <span i18n>Cancel</span>
      </button>
    } @else {
      @for (button of data.buttons; track $index) {
        <ng-container
          *ngTemplateOutlet="buttonTemplate; context: { $implicit: button }"
        ></ng-container>
      }
    }
  </div>
}

<ng-template #buttonTemplate let-button>
  <button
    (click)="closeDialog(button.callback)"
    [disabled]="button.isDisabled"
    type="button"
    [style.background-color]="button.color ?? DialogButtonColor.GREY"
    [style.color]="
      button.color === DialogButtonColor.GREY ? '#14181C' : '#FEFEFE'
    "
  >
    <i class="material-symbols-rounded">{{ button.matSymbol.symbol }}</i>
    <span>{{ button.text }}</span>
  </button>
</ng-template>
