import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatUnit',
  standalone: true,
})
export class FormatUnitPipe implements PipeTransform {
  private attachedUnits: string[] = ['%']; // units attached to the number without a space

  transform(unit: string | null | undefined): string {
    if (!unit) return '';

    return this.attachedUnits.includes(unit.trim().toLowerCase())
      ? unit
      : ` ${unit}`;
  }
}
