import { Directive, ElementRef, HostListener, input } from '@angular/core';

declare global {
  interface Document {
    mozCancelFullScreen?: () => Promise<void>;
    msExitFullscreen?: () => Promise<void>;
    webkitExitFullscreen?: () => Promise<void>;
    mozFullScreenElement?: Element;
    msFullscreenElement?: Element;
    webkitFullscreenElement?: Element;
    onwebkitfullscreenchange?: (this: Document, ev: Event) => void;
    onmsfullscreenchange?: (this: Document, ev: Event) => void;
    onmozfullscreenchange?: (this: Document, ev: Event) => void;
  }

  interface HTMLElement {
    msRequestFullScreen?: () => Promise<void>;
    mozRequestFullScreen?: () => Promise<void>;
    webkitRequestFullScreen?: () => Promise<void>;
  }
}

@Directive({
  selector: '[poziFullScreen]',
  standalone: true,
})
export class FullScreenDirective {
  elementToSendFullScreen = input<HTMLElement>();

  constructor(private el: ElementRef) {}

  @HostListener('click') onClick() {
    if (!this.elementToSendFullScreen()) {
      return;
    }
    if (this.isFullScreen()) {
      this.exitFullScreen();
    } else {
      this.requestFullScreen();
    }

    document.onwebkitfullscreenchange =
      document.onmsfullscreenchange =
      document.onmozfullscreenchange =
      document.onfullscreenchange =
        () => {
          if (this.isFullScreen()) {
            this.el.nativeElement.innerHTML =
              '<i class="material-symbols-rounded">fullscreen_exit</i>';
          } else {
            this.el.nativeElement.innerHTML =
              '<i class="material-symbols-rounded">fullscreen</i>';
          }
        };
  }

  isFullScreen(): boolean {
    return (
      (document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement) == this.elementToSendFullScreen()
    );
  }

  requestFullScreen() {
    const element = this.elementToSendFullScreen();
    if (!element) {
      return;
    }
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.webkitRequestFullScreen) {
      element.webkitRequestFullScreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.msRequestFullScreen) {
      element.msRequestFullScreen();
    }
  }

  exitFullScreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  }
}
