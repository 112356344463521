import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
} from '@angular/core';

@Directive({
  selector: '[poziScrollFade]',
  standalone: true,
})
export class ScrollFadeDirective implements AfterViewInit {
  protected el: HTMLElement;

  @HostListener('window:resize')
  onResize() {
    this.setScrollFade();
  }

  @HostListener('scroll')
  onScroll() {
    this.setScrollFade();
  }

  constructor(private elRef: ElementRef) {
    this.el = this.elRef.nativeElement;
  }

  ngAfterViewInit() {
    this.setScrollFade();
  }

  protected setScrollFade() {
    const verticalScrollable = this.el.clientHeight < this.el.scrollHeight;
    const horizontalScrollable = this.el.clientWidth < this.el.scrollWidth;
    const showVerticalBottomShadow =
      verticalScrollable &&
      this.el.scrollTop + this.el.clientHeight < this.el.scrollHeight;
    const showVerticalTopShadow = verticalScrollable && this.el.scrollTop > 0;
    const showHorizontalRightShadow =
      horizontalScrollable &&
      this.el.scrollLeft + this.el.clientWidth < this.el.scrollWidth;
    const showHorizontalLeftShadow =
      horizontalScrollable && this.el.scrollLeft > 0;

    this.el.style.setProperty(
      '--vertical-bottom-shadow-opacity',
      showVerticalBottomShadow ? '1' : '0'
    );
    this.el.style.setProperty(
      '--vertical-top-shadow-opacity',
      showVerticalTopShadow ? '1' : '0'
    );
    this.el.style.setProperty(
      '--horizontal-right-shadow-opacity',
      showHorizontalRightShadow ? '1' : '0'
    );
    this.el.style.setProperty(
      '--horizontal-left-shadow-opacity',
      showHorizontalLeftShadow ? '1' : '0'
    );
  }
}
