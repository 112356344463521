import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { formatDistance } from 'date-fns';
import { enUS, hu } from 'date-fns/locale';

@Pipe({
  name: 'formatDateDistance',
  standalone: true,
})
export class FormatDateDistancePipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string) {}

  transform(date: string): string {
    return formatDistance(new Date(date), new Date(), {
      addSuffix: true,
      locale: this.locale === 'hu' ? hu : enUS,
    })
      .replace('minutes', 'mins')
      .replace('minute', 'min')
      .replace('seconds', 'secs')
      .replace('second', 'sec')
      .replace('órával ezelőtt', 'órája')
      .replace('nappal ezelőtt', 'napja')
      .replace('perccel ezelőtt', 'perce');
  }
}
