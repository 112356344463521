import { NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  EventEmitter,
  HostListener,
  input,
  InputSignal,
  Output,
  TemplateRef,
  viewChild,
} from '@angular/core';
import { RouterLink } from '@angular/router';
import { OverlayDirective } from '../../../shared/overlay/overlay.directive';
import { Cell, MatsymbolCellValue } from '../../models/cell';
import { Row } from '../../models/row';
import { FormatDateDistancePipe } from '../../pipes/format-date-distance.pipe';
import { FormatDatePipe } from '../../pipes/format-date.pipe';
import { ColorService } from '../../services/color.service';

@Component({
  selector: 'pozi-table-cell',
  standalone: true,
  imports: [
    NgTemplateOutlet,
    FormatDatePipe,
    FormatDateDistancePipe,
    RouterLink,
    OverlayDirective,
  ],
  templateUrl: './table-cell.component.html',
  styleUrl: './table-cell.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableCellComponent {
  @HostListener('click') clicked() {
    this.cellClicked.emit(this.cell());
  }

  cell: InputSignal<Cell> = input.required<Cell>();
  row: InputSignal<Row> = input.required<Row>();
  rowId: InputSignal<number> = input.required<number>();
  readonly customCellTemplate = input<null | TemplateRef<{
    row: Row;
    rowId: number;
    cell: Cell;
  }>>(null, { alias: 'cellTemplate' });

  @Output() cellClicked = new EventEmitter<Cell>();

  readonly defaultCellTemplate = viewChild.required<
    TemplateRef<{ row: Row; rowId: number; cell: Cell }>
  >('defaultCellTemplate');
  readonly cellTemplate = computed(() =>
    this.customCellTemplate() !== null
      ? this.customCellTemplate()
      : this.defaultCellTemplate()
  );
  readonly route = computed<string | null>(
    () => this.cell()?.route || this.row()?.route || null
  );

  constructor(public color: ColorService) {}

  getFontVariationSettingsForMatSymbol(
    matsymbolCellValue: MatsymbolCellValue
  ): string {
    return `'FILL' ${matsymbolCellValue.matsymbol.fill ? '1' : '0'}`;
  }
}
