<div
  class="container"
  [style.height.px]="isInTileRow() ? 96 : 128"
  [style.margin-top.px]="isInTileRow() ? 0 : 8"
>
  <div class="title">{{ data().title }}</div>
  <div class="legend">
    @for (legend of data().legendItems; track legend) {
      <span>
        <i class="material-symbols-rounded" [style.color]="legend.color">
          circle
        </i>
        {{ legend.name }}
      </span>
    }
  </div>
  <div class="bars">
    @for (bar of data().calendarChartItems; track bar) {
      <div class="bar-container">
        <div
          class="progress-bar"
          [style.height.px]="getBarHeightInPixels(bar)"
          [style.background-color]="
            bar.heightPercentage
              ? (data().colors?.unfilled ?? defaultColors.unfilled)
              : (data().colors?.empty ?? defaultColors.empty)
          "
          poziOverlay
          [template]="!isEmpty(bar) && bar.date ? tooltipTemplate : undefined"
          [templateContext]="{ date: bar.date, tooltip: bar.tooltip }"
          [cdkOverlayPositionPairs]="cdkOverlayPositionPairs"
        >
          @if (bar.filledPercentage) {
            <div
              class="inner-progress-bar"
              [style.height.%]="bar.filledPercentage"
              [style.background-color]="
                data().colors?.filled ?? defaultColors.filled
              "
            ></div>
          }
        </div>
        <span
          class="date-label"
          [style.color]="isEmpty(bar) ? '#8CA0B4' : 'inherit'"
          >{{ dateLabels()[$index] }}</span
        >
      </div>
    }
  </div>
</div>

<ng-template #tooltipTemplate let-date="date" let-tooltip="tooltip">
  <div class="tooltip" [class.tooltip-block]="tooltip && tooltip.length">
    <div class="text">{{ date | formatDate: 'yyyy.MM.dd' }}</div>
    @for (value of tooltip; track value) {
      <div
        class="text"
        [style.color]="value.color || '#14181c'"
        [class.bold]="value.bold"
      >
        {{ value.text }}
      </div>
    }
  </div>
</ng-template>
