<div
  poziScrollFade
  class="tile-grid-container"
  [style.grid-template-areas]="gridTemplateAreas()"
  [style.grid-template-columns]="gridTemplateColumns()"
  [style.grid-template-rows]="gridTemplateRows()"
>
  @for (key of keys(); track key) {
    @if (isMapTile(grid().tiles[key])) {
      <pozi-google-maps
        [style.grid-area]="key"
        [input]="googleMapsInputMap().get(key)!"
        [isBackToOverviewButtonEnabled]="false"
        [mapOptionsOverride]="nonInteractiveGoogleMapsOptions"
      ></pozi-google-maps>
    } @else if (grid().tiles[key].hasOwnProperty('values')) {
      <pozi-tile [tile]="getTile(key)" [style.grid-area]="key"></pozi-tile>
    } @else if (grid().tiles[key].hasOwnProperty('calendarChartItems')) {
      <pozi-calendar-chart
        [isInTileRow]="true"
        [data]="getCalendarChart(key)"
        [style.grid-area]="key"
      ></pozi-calendar-chart>
    }
  }
</div>
