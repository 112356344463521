import { Injectable } from '@angular/core';
import { SvgColor } from '../models/svg-color';

@Injectable({ providedIn: 'root' })
export class ColorService {
  calculated: { [index: string]: SvgColor } = {};

  constructor() {
  }

  getFilter(hex: string): string {
    if (hex === undefined || hex === null || hex.trim() === '') {
      return 'none';
    }
    if (!this.calculated[hex]) {
      this.calculated[hex] = new SvgColor(hex);
    }
    return this.calculated[hex].Filter;
  }
}
