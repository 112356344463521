@if (route()) {
  <a class="cell" [routerLink]="route()">
    <ng-container
      *ngTemplateOutlet="
        cellTemplate();
        context: { cell: cell(), row: row(), rowId: rowId() }
      "
    ></ng-container>
  </a>
} @else {
  <div class="cell" [routerLink]="route()">
    <ng-container
      *ngTemplateOutlet="
        cellTemplate();
        context: { cell: cell(), row: row(), rowId: rowId() }
      "
    ></ng-container>
  </div>
}
<ng-template #defaultCellTemplate let-cell="cell">
  @for (value of cell.values; track $index) {
    @if (value.hasOwnProperty('text')) {
      <span
        [class.bold]="value.bold"
        [class.italic]="value.italic"
        [style.color]="value.color"
        poziOverlay
        [template]="value.hasOwnProperty('tooltip') ? tooltipTemplate : null"
        [templateContext]="{ tooltip: value.tooltip }"
        [title]="value.hasOwnProperty('alt') ? value.alt : ''"
        class="text"
        [routerLink]="value.link"
        [class.clickable]="value.link"
        >{{ value.text }}</span
      >
      @if (cell.metadata?.splitBy && $index !== cell.values.length - 1) {
        <span> {{ cell.metadata?.splitBy }} </span>
      }
    } @else if (value.hasOwnProperty('icon')) {
      <img
        [alt]="value.icon"
        [src]="'assets/img/' + value.icon"
        poziOverlay
        [template]="value.hasOwnProperty('tooltip') ? tooltipTemplate : null"
        [templateContext]="{ tooltip: value.tooltip }"
        [style.filter]="color.getFilter(value.color)"
        [title]="value.hasOwnProperty('alt') ? value.alt : ''"
        class="icon"
      />
    } @else if (value.hasOwnProperty('matsymbol')) {
      <i
        [style.color]="value.color"
        poziOverlay
        [template]="value.hasOwnProperty('tooltip') ? tooltipTemplate : null"
        [templateContext]="{ tooltip: value.tooltip }"
        [title]="value.hasOwnProperty('alt') ? value.alt : ''"
        [className]="
          'material-symbols-' +
          (value.matsymbol.style ? value.matsymbol.style : 'rounded')
        "
        [style.font-variation-settings]="
          getFontVariationSettingsForMatSymbol(value)
        "
        [style.transform]="
          value.matsymbol.rotationAngle &&
          'rotate(' + value.matsymbol.rotationAngle + 'deg)'
        "
        [style.font-size.px]="value.matsymbol.size ?? 20"
        >{{ value.matsymbol.symbol }}</i
      >
    } @else if (value.hasOwnProperty('progress')) {
      @if (value.progress < 50) {
        <div
          poziOverlay
          [template]="value.hasOwnProperty('tooltip') ? tooltipTemplate : null"
          [templateContext]="{ tooltip: value.tooltip }"
          [style.background-image]="
            'linear-gradient(90deg, #f0f0f2 50%, transparent 50%, transparent), linear-gradient(' +
            (90 + 3.6 * value.progress) +
            'deg, ' +
            (value.color || '#ababab') +
            ' 50%, #f0f0f2 50%, #f0f0f2)'
          "
          [title]="value.hasOwnProperty('alt') ? value.alt : ''"
          class="progress-radial"
        ></div>
      } @else {
        <div
          poziOverlay
          [template]="value.hasOwnProperty('tooltip') ? tooltipTemplate : null"
          [templateContext]="{ tooltip: value.tooltip }"
          [style.background-image]="
            'linear-gradient(' +
            (-90 + 3.6 * (value.progress - 50)) +
            'deg, ' +
            (value.color || '#ababab') +
            ' 50%, transparent 50%, transparent), linear-gradient(270deg, ' +
            (value.color || '#ababab') +
            ' 50%, #f0f0f2 50%, #f0f0f2)'
          "
          [title]="value.hasOwnProperty('alt') ? value.alt : ''"
          class="progress-radial"
        ></div>
      }
    } @else if (value.hasOwnProperty('progressBar')) {
      <div
        [title]="value.hasOwnProperty('alt') ? value.alt : ''"
        poziOverlay
        [template]="value.hasOwnProperty('tooltip') ? tooltipTemplate : null"
        [templateContext]="{ tooltip: value.tooltip }"
        class="progress-bar"
      >
        <div
          [style.background-color]="value.color || '#f05014'"
          [style.width.%]="value.progressBar"
          class="inner-progress-bar"
        ></div>
      </div>
    } @else if (value.hasOwnProperty('date')) {
      <span
        [title]="value.hasOwnProperty('alt') ? value.alt : ''"
        poziOverlay
        [template]="value.hasOwnProperty('tooltip') ? tooltipTemplate : null"
        [templateContext]="{ tooltip: value.tooltip }"
        class="text"
      >
        {{ value.date | formatDate: value.dateFormat }}
      </span>
    } @else if (value.hasOwnProperty('dateDistance')) {
      <span
        [title]="value.hasOwnProperty('alt') ? value.alt : ''"
        poziOverlay
        [template]="
          value.hasOwnProperty('tooltip') ? tooltipTemplate : dateDistTmpl
        "
        [templateContext]="{ tooltip: value.tooltip, date: value.dateDistance }"
        class="text"
      >
        {{ value.dateDistance | formatDateDistance }}
      </span>
      <ng-template #dateDistTmpl let-date="date">
        <span>{{ date | formatDate }}</span>
      </ng-template>
    }
  }
  @if (!cell.values || !cell.values.length) {
    <span class="empty"></span>
  }
</ng-template>

<ng-template #tooltipTemplate let-tooltip="tooltip">
  <div class="tooltip">
    @for (t of tooltip; track $index) {
      @if (t.text) {
        <span [style.color]="t.color || '#14181c'">{{ t.text }}</span>
        @if (!$last) {
          <span>, </span>
        }
      }
    }
  </div>
</ng-template>
