<div
  class="tile"
  [style.padding]="tile().extension ? '8px 8px 0px 8px' : '8px'"
>
  <div class="main">
    <div class="column">
      <div class="header">
        <div class="title">
          <span>{{ tile().title }}</span>
          @if (tile().subtitle) {
            <span class="subtitle">{{ tile().subtitle }}</span>
          }
        </div>

        @if (tile().link !== undefined) {
          <a class="action" [routerLink]="tile().link">
            <i class="material-symbols-rounded">north_east</i>
          </a>
        }
      </div>

      <div class="values">
        <ng-container
          *ngTemplateOutlet="valuesTemplate; context: { values: tile().values }"
        ></ng-container>
      </div>
    </div>

    @if (tile().chart) {
      <div class="column">
        <div class="chart-container" [id]="tile().chart!.id">
          <pozi-donut-chart [chart]="tile().chart!"></pozi-donut-chart>
        </div>
      </div>
    }
  </div>
  @if (tile().extension) {
    <div class="extension">
      @for (line of tile().extension; track line) {
        <div class="extension-line">
          <ng-container
            *ngTemplateOutlet="valuesTemplate; context: { values: line }"
          ></ng-container>
        </div>
      }
    </div>
  }
</div>

<ng-template #valuesTemplate let-values="values">
  @for (value of values; track $index; let first = $first) {
    <ng-container
      *ngTemplateOutlet="valueTemplate; context: { value, first }"
    ></ng-container>
  }
</ng-template>

<ng-template #valueTemplate let-value="value" let-first="first">
  @if (value.hasOwnProperty('text')) {
    <span
      class="value"
      [class.first-value]="first"
      [style.font-size]="value.fontSize ? value.fontSize + 'px' : ''"
      >{{ value.text }}
      @if (value.subText) {
        <span class="sub-text">{{ value.subText }}</span>
      }
      <ng-container
        *ngTemplateOutlet="infoValueTemplate; context: { value, first }"
      ></ng-container>
    </span>
  }
  @if (value.hasOwnProperty('number')) {
    <span class="value" [class.first-value]="first">
      {{ value.prefix || '' }}{{ value.number | formatNumber: 0 : 2
      }}{{ value.unit || '' | formatUnit }}
      <ng-container
        *ngTemplateOutlet="infoValueTemplate; context: { value, first }"
      ></ng-container>
    </span>
  } @else if (value.hasOwnProperty('date')) {
    <span class="value" [class.first-value]="first">{{
      value.date | formatDate: value.dateFormat
    }}</span>
  } @else if (value.hasOwnProperty('date1') && value.hasOwnProperty('date2')) {
    <span class="value" [class.first-value]="first">
      @if (value.showDay) {
        <span class="capitalize">{{ getDay(value.date1) }};</span>
      }
      {{ value.date1 | formatDate: value.dateFormat }}
      -
      {{ value.date2 | formatDate: value.dateFormat }}
    </span>
  } @else if (value.hasOwnProperty('symbol')) {
    <i
      [style.color]="value.color ?? '#14181c'"
      class="material-symbols-rounded"
      [style.transform]="
        value.rotationAngle && 'rotate(' + value.rotationAngle + 'deg)'
      "
      >{{ value.symbol }}</i
    >
  }
</ng-template>

<ng-template #infoValueTemplate let-value="value" let-first="first">
  @if (value.info) {
    <span class="value-info">
      <ng-container
        *ngTemplateOutlet="valueTemplate; context: { value: value.info, first }"
      ></ng-container>
    </span>
  }
</ng-template>
