import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { LanguageService } from './shared/language/services/language.service';

@Component({
  selector: 'pozi-root',
  standalone: true,
  imports: [RouterOutlet],
  template: '<router-outlet/>',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(private languageService: LanguageService) {}

  ngOnInit(): void {
    this.languageService.switchToLanguageFromCookie();
  }
}
