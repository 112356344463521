import { Pipe, PipeTransform } from '@angular/core';
import { KeyValue, KeyValuePipe } from '@angular/common';

@Pipe({
  name: 'keyValueOriginalOrder',
  standalone: true
})
export class KeyValueOriginalOrderPipe extends KeyValuePipe implements PipeTransform {

  /**
   * Forces the keyvalue pipe to leave the original order of the map when iterating over it
   * @param a
   * @param b
   */
  keepOriginalOrder(a: KeyValue<any, any>, b: KeyValue<any, any>) {
    return 0;
  }

  override transform(value: any): any {
    return super.transform(value, this.keepOriginalOrder);
  }

}
