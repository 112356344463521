import { ConnectedPosition } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  input,
} from '@angular/core';
import { Chart, Slice } from '../../models/statistics';
import { OverlayDirective } from '../overlay/overlay.directive';
import { FormatNumberPipe } from '../pipes/format-number.pipe';

@Component({
  selector: 'pozi-donut-chart',
  standalone: true,
  imports: [OverlayDirective, CommonModule, FormatNumberPipe],
  templateUrl: './donut-chart.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DonutChartComponent implements OnInit {
  chart = input.required<Chart>();

  slices: Slice[] = [];
  viewBoxSize = 42;
  viewBox = `0 0 ${this.viewBoxSize} ${this.viewBoxSize}`;
  center = this.viewBoxSize / 2;
  circumference = 100;
  strokeWidth = 4;
  startOffset = 25;
  radius = this.circumference / (2 * Math.PI);
  ringStrokeColor = '#E8EEF4';
  cdkOverlayPositionPairs: ConnectedPosition[] = [
    {
      offsetX: 6,
      offsetY: 0,
      originX: 'end',
      originY: 'center',
      overlayX: 'start',
      overlayY: 'center',
      panelClass: 'right',
    },
    {
      offsetX: -6,
      offsetY: 0,
      originX: 'start',
      originY: 'center',
      overlayX: 'end',
      overlayY: 'center',
      panelClass: 'left',
    },
    {
      offsetX: 0,
      offsetY: 6,
      originX: 'center',
      originY: 'bottom',
      overlayX: 'center',
      overlayY: 'top',
      panelClass: 'bottom',
    },
    {
      offsetX: 0,
      offsetY: -6,
      originX: 'center',
      originY: 'top',
      overlayX: 'center',
      overlayY: 'bottom',
      panelClass: 'top',
    },
  ];

  ngOnInit() {
    this.calculateSlices();
  }

  calculateSlices() {
    let cumulativeValue = 0;

    this.slices = this.chart()
      .data.map((item, index) => {
        // stroke-dasharray starts on the right side (at 3:00), and moves clockwise
        // stroke-dashoffset moves counter-clockwise
        const valuePercentage = item.value;
        const strokeDasharray = `${valuePercentage} ${100 - valuePercentage}`;
        const strokeDashoffset =
          index === 0
            ? this.startOffset
            : this.circumference - cumulativeValue + this.startOffset;
        cumulativeValue += valuePercentage;

        return {
          color: item.color,
          strokeDasharray: strokeDasharray,
          strokeDashoffset: strokeDashoffset,
        };
      })
      .reverse();
  }
}
