import { ConnectedPosition } from '@angular/cdk/overlay';

export const defaultDropdownPositionPairs: ConnectedPosition[] = [
  {
    offsetX: 0,
    offsetY: 6,
    originX: 'start',
    originY: 'bottom',
    overlayX: 'start',
    overlayY: 'top',
    panelClass: 'bottom-start',
  },
  {
    offsetX: 0,
    offsetY: 6,
    originX: 'end',
    originY: 'bottom',
    overlayX: 'end',
    overlayY: 'top',
    panelClass: 'bottom-end',
  },
  {
    offsetX: 0,
    offsetY: 6,
    originX: 'center',
    originY: 'bottom',
    overlayX: 'center',
    overlayY: 'top',
    panelClass: 'bottom',
  },
  {
    offsetX: 0,
    offsetY: -6,
    originX: 'start',
    originY: 'top',
    overlayX: 'start',
    overlayY: 'bottom',
    panelClass: 'top-start',
  },
  {
    offsetX: 0,
    offsetY: -6,
    originX: 'end',
    originY: 'top',
    overlayX: 'end',
    overlayY: 'bottom',
    panelClass: 'top-end',
  },
  {
    offsetX: 0,
    offsetY: -6,
    originX: 'center',
    originY: 'top',
    overlayX: 'center',
    overlayY: 'bottom',
    panelClass: 'top',
  },
  {
    offsetX: 6,
    offsetY: 0,
    originX: 'end',
    originY: 'center',
    overlayX: 'start',
    overlayY: 'center',
    panelClass: 'right',
  },
  {
    offsetX: 6,
    offsetY: 0,
    originX: 'end',
    originY: 'top',
    overlayX: 'start',
    overlayY: 'top',
    panelClass: 'right-start',
  },
  {
    offsetX: 6,
    offsetY: 0,
    originX: 'end',
    originY: 'bottom',
    overlayX: 'start',
    overlayY: 'bottom',
    panelClass: 'right-end',
  },
  {
    offsetX: -6,
    offsetY: 0,
    originX: 'start',
    originY: 'center',
    overlayX: 'end',
    overlayY: 'center',
    panelClass: 'left',
  },
  {
    offsetX: -6,
    offsetY: 0,
    originX: 'start',
    originY: 'top',
    overlayX: 'end',
    overlayY: 'top',
    panelClass: 'left-start',
  },
  {
    offsetX: -6,
    offsetY: 0,
    originX: 'start',
    originY: 'bottom',
    overlayX: 'end',
    overlayY: 'bottom',
    panelClass: 'left-end',
  },
];

export const defaultTooltipPositionPairs: ConnectedPosition[] = [
  // bottom
  {
    offsetX: 0,
    offsetY: 8,
    originX: 'start',
    originY: 'bottom',
    overlayX: 'start',
    overlayY: 'top',
    panelClass: 'bottom-start',
  },
  {
    offsetX: 0,
    offsetY: 8,
    originX: 'end',
    originY: 'bottom',
    overlayX: 'end',
    overlayY: 'top',
    panelClass: 'bottom-end',
  },
  {
    offsetX: 0,
    offsetY: 8,
    originX: 'center',
    originY: 'bottom',
    overlayX: 'center',
    overlayY: 'top',
    panelClass: 'bottom',
  },
  // top
  {
    offsetX: 0,
    offsetY: -8,
    originX: 'start',
    originY: 'top',
    overlayX: 'start',
    overlayY: 'bottom',
    panelClass: 'top-start',
  },
  {
    offsetX: 0,
    offsetY: -8,
    originX: 'end',
    originY: 'top',
    overlayX: 'end',
    overlayY: 'bottom',
    panelClass: 'top-end',
  },
  {
    offsetX: 0,
    offsetY: -8,
    originX: 'center',
    originY: 'top',
    overlayX: 'center',
    overlayY: 'bottom',
    panelClass: 'top',
  },
  // right
  {
    offsetX: 8,
    offsetY: 0,
    originX: 'end',
    originY: 'center',
    overlayX: 'start',
    overlayY: 'center',
    panelClass: 'right',
  },
  {
    offsetX: 8,
    offsetY: 0,
    originX: 'end',
    originY: 'top',
    overlayX: 'start',
    overlayY: 'top',
    panelClass: 'right-start',
  },
  {
    offsetX: 8,
    offsetY: 0,
    originX: 'end',
    originY: 'bottom',
    overlayX: 'start',
    overlayY: 'bottom',
    panelClass: 'right-end',
  },
  // left
  {
    offsetX: -8,
    offsetY: 0,
    originX: 'start',
    originY: 'center',
    overlayX: 'end',
    overlayY: 'center',
    panelClass: 'left',
  },
  {
    offsetX: -8,
    offsetY: 0,
    originX: 'start',
    originY: 'top',
    overlayX: 'end',
    overlayY: 'top',
    panelClass: 'left-start',
  },
  {
    offsetX: -8,
    offsetY: 0,
    originX: 'start',
    originY: 'bottom',
    overlayX: 'end',
    overlayY: 'bottom',
    panelClass: 'left-end',
  },
];
