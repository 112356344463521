/* eslint-disable @typescript-eslint/no-require-imports */
import { EnvironmentType } from '../app/models/environment.type';

export const environment: EnvironmentType = {
  production: false,
  language: 'en',
  about: {
    version: require('../../package.json').version,
    buildDate: 1743427055905,
  },
  socketUrl: '/socket',
};
