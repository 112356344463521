import {
  ConnectionPositionPair,
  OverlayContainer,
  OverlayModule,
} from '@angular/cdk/overlay';
import { CommonModule, DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  LOCALE_ID,
  NgZone,
  OnDestroy,
  Signal,
  ViewChild,
  computed,
  effect,
  input,
  signal,
} from '@angular/core';
import {
  GoogleMap,
  MapAdvancedMarker,
  MapInfoWindow,
  MapPolygon,
  MapPolyline,
} from '@angular/google-maps';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Loader } from '@googlemaps/js-api-loader';
import {
  Marker,
  Polygon,
  Polyline,
  Svg,
  baseMapOptions,
  createMapOverlayClass,
  createSvgOverlayClass,
} from '../../models/map';
import { KeyValueOriginalOrderPipe } from '../../table/pipes/key-value-original-order.pipe';
import { FullScreenDirective } from '../fullscreen/fullscreen.directive';
import { SharedVariablesService } from '../shared-variables/shared-variables.service';
import { AmLineChartItem } from '../timeline-chart/models/timeline-chart-input.interface';
import { TimelineChartComponent } from '../timeline-chart/timeline-chart.component';
import {
  GoogleMapsInput,
  GoogleMapsLayerOption,
} from './model/google-maps-input.interface';

@Component({
  selector: 'pozi-google-maps',
  standalone: true,
  imports: [
    GoogleMap,
    CommonModule,
    FullScreenDirective,
    KeyValueOriginalOrderPipe,
    MapAdvancedMarker,
    MapInfoWindow,
    MapPolyline,
    MapPolygon,
    RouterLink,
    TimelineChartComponent,
    OverlayModule,
  ],
  providers: [DatePipe],
  templateUrl: './google-maps.component.html',
  styleUrl: './google-maps.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GoogleMapsComponent implements OnDestroy {
  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow | undefined;
  @ViewChild('mapContainer') mapContainer: ElementRef | undefined;

  @HostListener('document:fullscreenchange')
  @HostListener('document:webkitfullscreenchange')
  @HostListener('document:mozfullscreenchange')
  @HostListener('document:MSFullscreenChange')
  onFullscreenChange() {
    this.updateOverlayContainerPosition();
  }

  input = input<GoogleMapsInput>({});
  isBackToOverviewButtonEnabled = input<boolean>(true);

  readonly markers: Signal<Marker[]> = computed(() => {
    return (
      this.input()?.drawings?.markers ??
      (this.markerFromTimeline() ? [this.markerFromTimeline()!] : [])
    );
  });
  readonly polygons: Signal<Polygon[]> = computed(() => {
    return this.input()?.drawings?.polygons ?? [];
  });
  readonly polylines: Signal<Polyline[]> = computed(() => {
    return this.input()?.drawings?.polylines ?? [];
  });
  readonly svgs: Signal<Svg[]> = computed(() => {
    return (
      this.input()?.drawings?.svgs?.filter(
        (svg: Svg) => !!svg.svg && !!svg.bounds.max && !!svg.bounds.min
      ) ?? []
    );
  });
  readonly isDrawingsEmpty: Signal<boolean> = computed(() => {
    return (
      this.markers().length +
        this.polygons().length +
        this.polylines().length +
        this.svgs().length ===
      0
    );
  });

  // Controls
  readonly hasMapTypeControl: Signal<boolean> = computed(() => {
    return !!this.input()?.controls?.mapType;
  });
  readonly hasFullScreenControl: Signal<boolean> = computed(() => {
    return !!this.input()?.controls?.fullScreen;
  });
  readonly hasZoomControl: Signal<boolean> = computed(() => {
    return !!this.input()?.controls?.zoom;
  });
  readonly layers: Signal<GoogleMapsLayerOption[]> = computed(() => {
    return this.input()?.controls?.layers ?? [];
  });

  //Map Options
  readonly defaultMapOptions: google.maps.MapOptions = {
    ...baseMapOptions,
    mapTypeId: 'roadmap',
    center: { lat: 47.162494, lng: 19.503304 },
    zoom: 7,
  };

  mapOptionsOverride = input<google.maps.MapOptions | undefined>();

  mapOptions = computed<google.maps.MapOptions>(() => ({
    ...this.defaultMapOptions,
    ...this.mapOptionsOverride(),
  }));

  // RouterLink
  readonly routerLink = computed<string | string[] | null | undefined>(() => {
    return this.input().routerLink;
  });

  readonly isOnlyOneMarkerPresent = computed<boolean>(() => {
    return (
      [...this.svgs(), ...this.polylines(), ...this.polygons()].length === 0 &&
      this.markers().length === 1
    );
  });

  isLayerSelectorDropdownOpen = signal(false);

  markerFromTimeline = signal<Marker | null>(null);

  isProgrammaticChange = false; // to distinguish user interaction
  freeRoam = signal<boolean>(false);

  map: google.maps.Map | undefined;
  mapBounds: google.maps.LatLngBounds | undefined;

  mapTypeIds: Record<string, string> = {
    roadmap: $localize`Default`,
    hybrid: $localize`Satellite`,
  };

  layerSelectorCdkOverlayPositionPairs: ConnectionPositionPair[] = [
    {
      offsetX: 0,
      offsetY: -6,
      originX: 'end',
      originY: 'top',
      overlayX: 'end',
      overlayY: 'bottom',
    },
  ];

  selectedLayers = signal<string[]>([]);

  svgOverlays: google.maps.OverlayView[] = [];
  mapOverlay: any | undefined;

  constructor(
    private sharedVariablesService: SharedVariablesService,
    @Inject(LOCALE_ID) private locale: string,
    private datePipe: DatePipe,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private zone: NgZone,
    private overlayContainer: OverlayContainer
  ) {
    this.loadGoogleMaps();

    // manually creating signal subscription, to ensure that change detection is triggered and the template is updated if the signal value is changed outside of zone (e.g. in: this.addEventListeners())
    effect(() => this.freeRoam());
  }

  onMapInitialized(map: google.maps.Map) {
    this.map = map;

    this.appendTransparentMapOverlay();
    this.initLayers();
    this.svgs().forEach((svg: Svg) => {
      this.appendSvg(svg);
    });
    if (!this.isDrawingsEmpty()) {
      this.adjustMapView();
    }
    this.updateSvgPathStrokeWidth();
    if (this.isBackToOverviewButtonEnabled()) {
      this.addEventListeners();
    }
  }

  initLayers() {
    this.zone.run(() => {
      this.selectedLayers.set(
        this.layers()
          .filter((layer: GoogleMapsLayerOption) => layer.isSelected)
          .map((layer: GoogleMapsLayerOption) => layer.key)
      );
      this.updateLayersInUrl(this.selectedLayers());
    });
  }

  appendTransparentMapOverlay() {
    if (this.map && !this.input().grayscale) {
      if (this.mapOverlay) {
        this.mapOverlay.onRemove();
      }
      const MapOverlay = createMapOverlayClass();
      this.mapOverlay = new MapOverlay(this.map);
    }
  }

  updateLayersInUrl(layers: string[]): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        layers: layers.length ? layers : null,
      },
      queryParamsHandling: 'merge',
    });
  }

  onLayerClick(layerKey: string): void {
    const index = this.selectedLayers().indexOf(layerKey);
    const selectedLayers = this.selectedLayers();
    if (index === -1) {
      selectedLayers.push(layerKey);
      this.svgs().forEach((svg: Svg, index: number) => {
        if (svg.layer === layerKey) {
          this.svgOverlays[index].setMap(this.map!);
        }
      });
    } else {
      selectedLayers.splice(index, 1);
      this.svgs().forEach((svg: Svg, index: number) => {
        if (svg.layer === layerKey) {
          this.svgOverlays[index].setMap(null);
        }
      });
    }
    this.selectedLayers.set(selectedLayers);
    this.updateLayersInUrl(selectedLayers);
  }

  zoomIn() {
    if (this.map) {
      this.map.setZoom(this.map.getZoom()! + 1);
    }
  }

  zoomOut() {
    if (this.map) {
      this.map.setZoom(this.map.getZoom()! - 1);
    }
  }

  /**
   * Sets --svg-path-stroke-width to be used in the scss.
   * The stroke-width of the SVG's path (polyline) is static. Therefore it becomes small, then eventually disappears uppon zooming out.
   * To counter this, we dinamically overwrite the stroke-width's value based on the zoom level.
   * Zoom level ranges from 0 to 22.
   * We raise to the power 2, to double the value according to zoom level. Then divide by 3, reduce the differences.
   */
  updateSvgPathStrokeWidth(): void {
    const strokeWidth = Math.pow(2, 22 - this.map!.getZoom()!) / 3;
    document.documentElement.style.setProperty(
      '--svg-path-stroke-width',
      strokeWidth.toString()
    );
  }

  setMapTypeId(mapTypeId: string) {
    if (this.map) {
      this.map.setMapTypeId(mapTypeId);
      this.appendTransparentMapOverlay();
    }
  }

  openInfoWindow(
    marker: MapAdvancedMarker,
    infoWindowContent: HTMLElement,
    infoWindow: MapInfoWindow,
    markerIndex: number
  ) {
    if (
      this.markers().at(markerIndex)?.infoWindowTemplate &&
      marker &&
      infoWindow
    ) {
      infoWindow.open(marker, false, infoWindowContent);
    }
  }

  private loadGoogleMaps(): void {
    const loader = new Loader({
      apiKey: this.sharedVariablesService.getVar('googleMapsApiKey'),
      version: 'weekly',
      language: this.locale,
      region: this.locale === 'hu' ? 'HU' : 'US',
    });
    loader.importLibrary('maps').catch((e) => console.error(e));
  }

  onBackToOverviewButtonClick(): void {
    this.freeRoam.set(false);
    this.adjustMapView();
  }

  private adjustMapView(): void {
    this.isProgrammaticChange = true;
    if (this.isOnlyOneMarkerPresent()) {
      this.map?.setZoom(17);
      this.map?.panTo(this.markers().at(0)!.position);
    } else {
      this.fitBounds();
    }
  }

  private fitBounds(): void {
    if (this.map) {
      this.mapBounds = new google.maps.LatLngBounds();
      const positionsToExpandBy: (
        | google.maps.LatLngLiteral
        | google.maps.LatLng
      )[] = [
        ...this.svgs().flatMap((svg: Svg) => [svg.bounds.min, svg.bounds.max]),
        ...this.markers().map((marker: Marker) => marker.position),
        ...this.polylines().flatMap((polyline: Polyline) => polyline.positions),
        ...this.polygons().flatMap((polygon: Polygon) => polygon.paths),
      ];
      positionsToExpandBy.forEach(
        (position: google.maps.LatLngLiteral | google.maps.LatLng) => {
          this.mapBounds?.extend(position);
        }
      );
      this.map.fitBounds(this.mapBounds, this.getBoundsPadding());
    }
  }

  private getBoundsPadding(): number | google.maps.Padding {
    if (this.input().timeline) {
      return {
        top: 0,
        right: 0,
        left: 0,
        bottom: 220,
      };
    } else {
      return 0;
    }
  }

  private appendSvg(svg: Svg) {
    if (this.map && svg?.svg) {
      const bounds = new google.maps.LatLngBounds(
        svg.bounds.min,
        svg.bounds.max
      );
      const SvgOverlay = createSvgOverlayClass();
      const overlay = new SvgOverlay(bounds, svg.svg);
      this.svgOverlays.push(overlay);

      if (!svg.layer || this.selectedLayers().includes(svg.layer)) {
        overlay.setMap(this.map);
      }
    }
  }

  setMarkerFromTimeline(amLineChartItem: AmLineChartItem): void {
    this.markerFromTimeline.set({
      matsymbol: this.input().timeline?.amChart?.mapMarkerSymbol,
      position: amLineChartItem.position,
      label: this.datePipe.transform(new Date(amLineChartItem.date), 'HH:mm'),
    } as Marker);
    this.cdr.detectChanges();
  }

  onMapDrag(): void {
    this.freeRoam.set(true);
  }

  addEventListeners(): void {
    ['bounds_changed', 'center_changed', 'zoom_changed'].map(
      (event: string) => {
        this.map!.addListener(event, () => {
          if (event === 'bounds_changed' && this.isProgrammaticChange) {
            this.isProgrammaticChange = false;
          } else if (
            event !== 'bounds_changed' &&
            !this.isProgrammaticChange &&
            !this.freeRoam()
          ) {
            this.freeRoam.set(true);
          }
        });
      }
    );
  }

  toggleLayerSelectorDropdown() {
    this.isLayerSelectorDropdownOpen.update((value: boolean) => !value);
  }

  private updateOverlayContainerPosition() {
    this.cdr.detectChanges();
    if (this.isFullscreen()) {
      this.attachOverlayContainerToMap();
    } else {
      this.resetOverlayContainerToDefaultPosition();
    }
  }

  private isFullscreen(): boolean {
    return (
      (document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement) == this.mapContainer?.nativeElement
    );
  }

  private attachOverlayContainerToMap() {
    this.mapContainer?.nativeElement.appendChild(
      this.overlayContainer.getContainerElement()
    );
  }

  private resetOverlayContainerToDefaultPosition() {
    document.body.appendChild(this.overlayContainer.getContainerElement());
  }

  ngOnDestroy(): void {
    this.resetOverlayContainerToDefaultPosition();
  }
}
