import { ConnectionPositionPair, OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import {
  Component,
  HostBinding,
  TemplateRef,
  forwardRef,
  input,
  model,
  signal,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { defaultDropdownPositionPairs } from '../overlay/cdk-overlay-position-pairs';

@Component({
  selector: 'pozi-dropdown',
  standalone: true,
  imports: [CommonModule, OverlayModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DropdownComponent),
    },
  ],
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent<T> implements ControlValueAccessor {
  @HostBinding('disabled') disabled = model<boolean>(false);

  items = input<T[]>([]);
  icon = input<string>('');
  itemGroup = input<string>('');
  showTitle = input<boolean>(true);
  template = input<TemplateRef<undefined> | null>(null);
  dropdownOpen = signal(false);
  cdkOverlayPositionPairs: ConnectionPositionPair[] =
    defaultDropdownPositionPairs;

  touched = false;
  selectedItem!: T;
  onChange: (model: T) => void = () => {};
  onTouched: () => void = () => {};

  selectItem(item: T) {
    if (!this.touched && this.selectedItem !== item) {
      this.onTouched();
      this.touched = true;
    }

    this.selectedItem = item;
    this.onChange(this.selectedItem);
    this.dropdownOpen.set(false);
  }

  toggleDropdown() {
    this.dropdownOpen.update((o) => !o);
  }

  registerOnChange(fn: (model: T) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled.set(isDisabled);
  }

  writeValue(model: T): void {
    this.selectedItem = model;
  }
}
