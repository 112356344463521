import { Color } from './color';
import { Solver } from './solver';

export class SvgColor {
  color: Color;
  solver: Solver;
  result: { filter: string; loss: number; values: number[] };

  constructor(hex: string) {
    this.color = new Color(0, 0, 0);
    this.color.setHex(hex);
    this.solver = new Solver(this.color);
    this.result = this.solver.solve();
  }

  get Filter(): string {
    return this.result.filter;
  }
}
