import { NgTemplateOutlet } from '@angular/common';
import { Component, Inject, LOCALE_ID, input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { format } from 'date-fns';
import { enUS, hu } from 'date-fns/locale';
import { Tile } from '../../models/statistics';
import { FormatDatePipe } from '../../table/pipes/format-date.pipe';
import { DonutChartComponent } from '../donut-chart/donut-chart.component';
import { FormatNumberPipe } from '../pipes/format-number.pipe';
import { FormatUnitPipe } from '../pipes/format-unit.pipe';

@Component({
  selector: 'pozi-tile',
  standalone: true,
  imports: [
    RouterLink,
    NgTemplateOutlet,
    FormatDatePipe,
    DonutChartComponent,
    FormatNumberPipe,
    FormatUnitPipe,
  ],
  templateUrl: './tile.component.html',
  styleUrl: './tile.component.scss',
})
export class TileComponent {
  tile = input.required<Tile>();

  constructor(@Inject(LOCALE_ID) private locale: string) {}

  getDay(date: string | Date): string {
    return format(date, 'EEEE', {
      locale: this.locale === 'hu' ? hu : enUS,
    });
  }
}
