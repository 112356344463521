<input
  type="checkbox"
  [id]="inputId()"
  [(ngModel)]="value"
  (ngModelChange)="valueChange.emit(value)"
/>
<label
  [for]="inputId()"
  [class.isEnabled]="value"
  [style.--color]="color()"
></label>
@if (title()) {
  <span class="toggle-title">{{ title() }}</span>
}
