import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  input,
  InputSignal,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'pozi-image-showcase',
  standalone: true,
  imports: [],
  templateUrl: './image-showcase.component.html',
  styleUrl: './image-showcase.component.scss',
})
export class ImageShowcaseComponent implements AfterViewInit {
  @ViewChild('thumbnails') thumbnails!: ElementRef;
  @ViewChild('leftScrollGradient') leftScrollGradient!: ElementRef;
  @ViewChild('rightScrollGradient') rightScrollGradient!: ElementRef;

  defaultEmptyText = $localize`:@@imageShowcase.empty:No pictures available`;
  emptyText: InputSignal<string> = input(this.defaultEmptyText);
  images: InputSignal<string[]> = input.required();
  currentIndex: number = 0;

  ngAfterViewInit(): void {
    this.initializeScrollGradientUpdates();
  }

  initializeScrollGradientUpdates(): void {
    this.updateScrollGradientVisibility();
    this.thumbnails.nativeElement.addEventListener('scroll', () =>
      this.updateScrollGradientVisibility()
    );
    window.addEventListener('resize', () =>
      this.updateScrollGradientVisibility()
    );
  }

  showImage(index: number): void {
    this.currentIndex = index;
    if (this.isScrollingNeeded(this.currentIndex)) {
      this.scrollToThumbnail(this.currentIndex);
    }
  }

  /**
   * We auto-scroll to an element, if the element or any of it's neighbor, is not fully visible
   *
   * @param index of the thumbnail
   * @returns true, if thumbnail or any of it's neighbor is not fully visible
   */
  isScrollingNeeded(index: number): boolean {
    const indexesToCheck = [index];
    if (index > 0) {
      indexesToCheck.push(index - 1);
    }
    if (index < this.images().length - 1) {
      indexesToCheck.push(index + 1);
    }

    return indexesToCheck.some(
      (index: number) => !this.isThumbnailFullyVisible(index)
    );
  }

  isThumbnailFullyVisible(index: number): boolean {
    const thumbnailsRect =
      this.thumbnails.nativeElement.getBoundingClientRect();
    const imageRect =
      this.thumbnails.nativeElement.children[index].getBoundingClientRect();
    return (
      imageRect.left >= thumbnailsRect.left &&
      imageRect.right <= thumbnailsRect.right
    );
  }

  @HostListener('window:keyup', ['$event'])
  handleKeyUp(event: KeyboardEvent): void {
    if (event.key === 'ArrowLeft') {
      this.showPreviousImage();
    } else if (event.key === 'ArrowRight') {
      this.showNextImage();
    }
  }

  showPreviousImage(): void {
    if (this.currentIndex > 0) {
      this.currentIndex--;
      if (this.isScrollingNeeded(this.currentIndex)) {
        this.scrollToThumbnail(this.currentIndex);
      }
    }
  }

  showNextImage(): void {
    if (this.currentIndex < this.images().length - 1) {
      this.currentIndex++;
      if (this.isScrollingNeeded(this.currentIndex)) {
        this.scrollToThumbnail(this.currentIndex);
      }
    }
  }

  updateScrollGradientVisibility(): void {
    if (!this.thumbnails?.nativeElement) {
      return;
    }
    const thumbnails = this.thumbnails.nativeElement;
    const leftScrollGradient = this.leftScrollGradient.nativeElement;
    const rightScrollGradient = this.rightScrollGradient.nativeElement;

    if (thumbnails.scrollLeft > 0) {
      leftScrollGradient.style.display = 'block';
    } else {
      leftScrollGradient.style.display = 'none';
    }

    if (
      thumbnails.scrollWidth >
      thumbnails.clientWidth + thumbnails.scrollLeft
    ) {
      rightScrollGradient.style.display = 'block';
    } else {
      rightScrollGradient.style.display = 'none';
    }
  }

  scrollToThumbnail(index: number): void {
    const thumbnails = this.thumbnails.nativeElement;
    const thumbnail = thumbnails.children[index];
    if (thumbnail) {
      thumbnail.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center',
      });
    }
  }
}
